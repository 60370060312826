import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import usLogo from "../../../assets/images/icons/US.svg";
import canadaLogo from "../../../assets/images/icons/canada.svg";
import ukLogo from "../../../assets/images/icons/united-kingdom.svg";
import ITlogo from "../../../assets/flags/IT.png";
import ESlogo from "../../../assets/flags/ES.png";
import DElogo from "../../../assets/flags/DE.png";
import FRlogo from "../../../assets/flags/FR.png";
import AUlogo from "../../../assets/flags/AU.png";
import AElogo from "../../../assets/flags/AE.png";
import BRlogo from "../../../assets/flags/BR.png";
import EGlogo from "../../../assets/flags/EG.png";
import GBlogo from "../../../assets/flags/GB.png";
import INlogo from "../../../assets/flags/IN.png";
import JPlogo from "../../../assets/flags/JP.jpg";
import MXlogo from "../../../assets/flags/MX.png";
import NLlogo from "../../../assets/flags/NL.png";
import SElogo from "../../../assets/flags/SE.png";
import SGlogo from "../../../assets/flags/SG.png";
import PLlogo from "../../../assets/flags/PL.png";
import TRlogo from "../../../assets/flags/TR.png";
import LogoIcon from "../../../assets/brand/logo-icon.svg";

interface Row {
  id: number;
  profileId: string;
  contryLogo: string;
  country: string;
  types: string;
  dataConnections: boolean;
  isLoading: boolean;
}

export interface AmazonConnectionTableRef {
  handleButtonClick: () => void;
}

const AmazonConnectionTable = forwardRef<AmazonConnectionTableRef, {}>(
  (_, ref) => {
    const [rows, setRows] = useState<Row[]>([]);
    const [apiLoading, setApiLoading] = useState(true);

    const handleSwitchToggle = async (id: number) => {
      const updatedRows = rows.map((row) =>
        row?.id === id ? { ...row, dataConnections: !row.dataConnections } : row
      );
      const trueDataConnections = updatedRows.filter(
        (row) => row.dataConnections
      );
      setRows(updatedRows);
      if (trueDataConnections.length > 0) {
        // Handle true data connections
      }
    };

    const handleButtonClick = async () => {
      console.log("handleButtonClick function called in AmazonConnectionTable");
      const trueDataConnections = rows.filter((row) => row.dataConnections);
      if (trueDataConnections.length > 0) {
        try {
          const response = await updateProfilePreferences(trueDataConnections);
          if (response.ok) {
            console.log("Profile preferences updated successfully!");
          } else {
            console.error(
              `Failed to update profile preferences. Status code: ${response.status}`
            );
          }
        } catch (error) {
          console.error("Error updating profile preferences:", error);
        }
      }
    };

    const updateProfilePreferences = async (
      updatedRows: Row[]
    ): Promise<Response> => {
      let userToken = localStorage.getItem("userToken");
      let AuthToken = "Bearer " + userToken;

      try {
        const response = await fetch(
          "https://api.aimosa.io/Users/Onboarding/ProfilePreferences",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthToken,
            },
            body: JSON.stringify({
              awsProfilePreferences: updatedRows.map(
                ({ profileId, dataConnections }) => ({
                  profileId,
                  isActive: dataConnections,
                })
              ),
            }),
          }
        );
        return response;
      } catch (error) {
        throw error;
      }
    };

    useImperativeHandle(ref, () => ({
      handleButtonClick,
    }));

    const columns: GridColDef[] = [
      {
        field: "contryLogo",
        headerName: "",
        width: 50,
        renderCell: (params) => (
          <img
            src={params?.row.contryLogo}
            alt=""
            style={{ width: 30, height: 30, borderRadius: 50 }}
          />
        ),
      },
      { field: "country", headerName: "Country", width: 250 },
      { field: "id", headerName: "ID", width: 250 },
      { field: "types", headerName: "Type", width: 250 },
      {
        field: "dataConnections",
        headerName: "Data Connection",
        width: 250,
        renderCell: (params) => (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id={`flexSwitchCheckChecked_${params?.row?.id}`}
              checked={params.row.dataConnections}
              onChange={() => handleSwitchToggle(params?.row?.id)}
            />
          </div>
        ),
      },
    ];

    useEffect(() => {
      const fetchData = async () => {
        let userToken = localStorage.getItem("userToken");
        let AuthToken = "Bearer " + userToken;
        try {
          const response = await fetch(
            "https://api.aimosa.io/AmazonConnection",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: AuthToken,
              },
            }
          );

          const data = await response.json();

          const formattedRows = data.result.map((item, index) => {
            console.log("Profile ID:", item.profilId);
            return {
              id: item?.id === null ? item.profilId : item.id,
              profileId: item.profilId,
              contryLogo: getCountryLogo(item.marketplace),
              country: item.marketplace,
              types: item.type,
              dataConnections: item.dataConnections,
            };
          });

          setRows(formattedRows);

          const trueDataConnections = formattedRows.filter(
            (row) => row.dataConnections
          );
          console.log("Rows with true dataConnections:", trueDataConnections);
          console.log("Total Rows:", formattedRows.length);
        } catch (error) {
          console.error("Error fetching Amazon Connection data:", error);
        } finally {
          setApiLoading(false);
        }
      };

      fetchData();
    }, []);

    const getCountryLogo = (country: string): string | null => {
      switch (country) {
        case "CA":
          return canadaLogo;
        case "US":
          return usLogo;
        case "UK":
          return ukLogo;
        case "IT":
          return ITlogo;
        case "ES":
          return ESlogo;
        case "DE":
          return DElogo;
        case "FR":
          return FRlogo;
        case "AU":
          return AUlogo;
        case "AE":
          return AElogo;
        case "BR":
          return BRlogo;
        case "EG":
          return EGlogo;
        case "GB":
          return GBlogo;
        case "IN":
          return INlogo;
        case "JP":
          return JPlogo;
        case "MX":
          return MXlogo;
        case "NL":
          return NLlogo;
        case "SE":
          return SElogo;
        case "SG":
          return SGlogo;
        case "PL":
          return PLlogo;
        case "TR":
          return TRlogo;
        default:
          return null;
      }
    };

    return (
      <>
        {!apiLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              border: "1px solid #E5E7EB",
              borderRadius: "6px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns.map((column) => ({
                ...column,
                renderCell: (params) =>
                  column.field === "dataConnections" ? (
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheckChecked_${params.row.id}`}
                        checked={params.row.dataConnections}
                        onChange={() => handleSwitchToggle(params.row.id)}
                      />
                    </div>
                  ) : (
                    column.renderCell && column.renderCell(params)
                  ),
              }))}
              checkboxSelection={false}
              hideFooter={true}
              rowHeight={50}
            />
          </div>
        ) : (
          <div className="loading-container">
            <div className="loading-text">
              <span className="logicon">
                <img src={LogoIcon} alt="logo-small"></img>
              </span>
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default AmazonConnectionTable;
