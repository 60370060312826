import React, { useState, useEffect, useRef } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./index.css";
import { Row, Col } from "react-bootstrap";
import { Nav, Tab } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import LogoIcon from "../../../assets/brand/logo-icon.svg";
import ColumnFilter from "../../../components/Filters/cloumn-filter";
import ConditionFilter from "../../../components/Filters/condition-filter";

import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import PauseIcon from "../../../assets/images/icons/pause.svg";
import ExportIcon from "../../../assets/images/icons/export.svg";
import { toast } from "react-toastify";
import CampaignManagerService from "../../../services/CampaignManagerService";
import CamapaignManagerBulkOperation from "../../../components/CampaignManager/BulkOperation/CamapaignManagerBulkOperation";
import cloneDeep from 'lodash/cloneDeep';

let rows: any = [];
let columns: GridColDef[] = [];

const TargetingTable = ({ tabName, isBulkOperationsEnabled, metaData, filters }) => {
    const [loading, setLoading] = useState(false);
    let currPage = 1;

    const [selectedRowid, setSelectedRows] = useState<any>([]);
    const [perPage, setPerPage] = useState(50);
    const [lastPage, setLastPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [totalRow, setTotalRow] = useState(0);
    const [marketplace, setMarketplace] = useState("");
    const [searchKeyValue, setSearchKeyValue] = useState<any[]>([]);
    const [dropdownDatas, setDropdownDatas] = useState<any[]>([]);
    const [dropdownDataFilter, setDropdownDataFilter] = useState<any[]>([]);
    const [columFiltervalue, setColumFiltervalue] = useState<any[]>([]);
    const [selectAllchecked, setSelectAllChecked] = useState(true);

    const [hiddenColumns, setHiddenColumns] = useState<any[]>([]);
    const [csvData, setCsvData] = useState(null);

    const [internalFilters, setInternalFilters] = useState(filters);

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;

    const fetchData = async () => {
        try {
            let result = await CampaignManagerService.getTargetWiseData(internalFilters);
            setTotalRow(result.total);
            setPerPage(result.perPage);
            setActivePage(result.currPage);
            setLastPage(result.lastPage);
            let headers = result.headers;

            setDropdownDatas(headers);
            let defaultCheckAll = headers.map((item) => ({
                ...item,
                checked: true,
            }))
            setDropdownDataFilter(defaultCheckAll);
            const allKeys = headers.map((item) => item.keyName);
            setSearchKeyValue(allKeys);
            setHiddenColumns(allKeys);
            setMarketplace(result.data[0].marketplace);
            if (columns.length < 1) {
                for (let i = 0; headers.length > i; i++) {
                    if (headers[i]["keyName"] === "status") {
                        columns.push({
                            field: headers[i]["keyName"],
                            headerName: headers[i]["displayName"],
                            width: 80,
                            renderCell: (params) => (
                                <i className={"status " + params.row.status}></i>
                            ),
                        });
                    } else if (headers[i]["keyName"] === "marketplace") {
                        columns.push({
                            field: headers[i]["keyName"],
                            headerName: headers[i]["displayName"],
                            width: 120,

                            renderCell: (params) => (
                                <i className={"flag-" + params.row.marketplace}></i>
                            ),
                        });
                    } else if (headers[i]["keyName"] === "campaign") {
                        columns.push({
                            field: headers[i]["keyName"],
                            headerName: headers[i]["displayName"],
                            minWidth: 250,
                        });
                    } else {
                        columns.push({
                            field: headers[i]["keyName"],
                            headerName: headers[i]["displayName"],
                            minWidth: 100,
                        });
                    }
                }
            }
            rows = result.data;

        } catch (error) {
            console.error("Error fetching data:", error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        var updatedFilters = cloneDeep(filters);
        updatedFilters.pageNumber = internalFilters.pageNumber;
        updatedFilters.pageSize = internalFilters.pageSize;
        updatedFilters.globalFilters.searchText = internalFilters.globalFilters.searchText;
        updatedFilters.globalFilters.advancedFilters = cloneDeep(internalFilters.globalFilters.advancedFilters);
        setInternalFilters(updatedFilters);
    }, [filters]);

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [tabName, internalFilters]);

    const applyPageSize = (e) => {
        var updatedFilters = cloneDeep(internalFilters);
        updatedFilters.pageSize = parseInt(e.target.value);
        setInternalFilters(updatedFilters);
        console.log("applyPageSize");
    };

    const handleCallback = (childData) => {
        var updatedFilters = cloneDeep(internalFilters);
        updatedFilters.globalFilters.advancedFilters = cloneDeep(childData);
        setInternalFilters(updatedFilters);
        console.log("handleCallback");
    };

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            var updatedFilters = cloneDeep(internalFilters);
            updatedFilters.globalFilters.searchText = event.target.value;
            setInternalFilters(updatedFilters);
            console.log("handleCallback");
            event.preventDefault();
        }
    };

    const cancelDropdown = () => {
        const eleRm: any = document.getElementsByClassName("dropdown-menu");
        for (let i = 0; i < eleRm.length; i++) {
            eleRm[i].classList.remove("show");
        }
    };

    const handleChange = (event, value: number) => {
        currPage = value;
        var updatedFilters = cloneDeep(internalFilters);
        updatedFilters.pageNumber = value;
        setInternalFilters(updatedFilters);
    };

    const applyColumns = () => {
        setColumFiltervalue(searchKeyValue);
        setHiddenColumns(searchKeyValue);
        cancelDropdown();
    };

    // master check
    function onMasterCheck(e) {
        setSelectAllChecked(e.target.checked);

        // Update the checked state for all items in the list
        let checkAll = dropdownDataFilter.map((item) => ({
            ...item,
            checked: e.target.checked,
        }))
        setDropdownDataFilter(checkAll);
        // Update the searchKeyValue based on the master checkbox
        if (e.target.checked) {
            const allKeys = checkAll.map((item) => item.keyName);
            setSearchKeyValue(allKeys);
        } else {
            setSearchKeyValue([]);
        }
        console.log("dropdownDataFilter: ", dropdownDataFilter);
    }

    // set condiction
    function onItemCheck(i, e, item) {
        let updatedSelected = dropdownDataFilter;
        updatedSelected[i].checked = e.target.checked;
        setDropdownDataFilter(updatedSelected);
        if (e.target.checked === true) {
            setSearchKeyValue((current) => [...current, e.target.name]);
            console.log("setSearchKeyValue true " + setSearchKeyValue);
            let checkAll = dropdownDataFilter[i].checked
        }

        if (e.target.checked === false) {
            setSelectAllChecked(e.target.checked);
            setSearchKeyValue((current) =>
                current.filter((item) => item !== e.target.name)
            );
            console.log("setSearchKeyValue false " + setSearchKeyValue);
        }
    }

    const handleExportData = async () => {
        try {
            const response = await fetch(
                "https://api.aimosa.io/Ads/CampaignManager/Targets/Export",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: AuthToken,
                    },
                    body: JSON.stringify({}),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to export data");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "Targeting exported_data.csv";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            // Handle success
            console.log("Export successful");
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    };

    return (
        <div style={{ height: 500, width: "100%" }}>
            <Row className="mt-2 mb-2">
                <Col md={3}>
                    <CamapaignManagerBulkOperation
                        enabled={isBulkOperationsEnabled}
                        type="Target"
                        marketplace={marketplace}
                        rows={selectedRowid}
                        showStatus={true}
                        showDailyBudget={true}
                        showBiddingStrategy={false}
                    />
                </Col>
                <Col md={9}>
                    <div className="filter-container">
                        <Row>
                            <Col md={3} className="padding-lr-10"></Col>
                            <Col md={9}>
                                <div>
                                    <form>
                                        <div className="search-filter-container">
                                            <i className="fa fa-search"></i>
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                id="globalSearch"
                                                name="globalSearch"
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="filter-item filter-link-container dropdownContent">
                                    <p
                                        className="menu bookself"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                        aria-expanded="false"
                                        data-bs-display="static"
                                    >
                                        <i>
                                            <img src={FilterIcon} alt="filter icon" />
                                        </i>
                                        <span>Filter</span>
                                        <i
                                            className="fa fa-angle-down down-arrow-right"
                                            aria-hidden="true"
                                        ></i>
                                    </p>
                                    <div
                                        className="dropdown-menu dropdown-menu-lg-end"
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <ConditionFilter
                                            parentCallback={handleCallback}
                                            dropdownData={dropdownDatas}
                                            metaData={metaData}
                                        />
                                    </div>
                                </div>

                                <div className="filter-item column-link-container dropdownContent">
                                    <p
                                        className="menu bookself"
                                        id="dropdownMenuButton2"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                        aria-expanded="false"
                                        data-bs-display="static"
                                    >
                                        <i>
                                            <img src={PauseIcon} alt="filter icon" />
                                        </i>
                                        <span>Columns</span>
                                        <i
                                            className="fa fa-angle-down down-arrow-right"
                                            aria-hidden="true"
                                        ></i>
                                    </p>
                                    <div
                                        className="dropdown-menu dropdown-menu-lg-start column-filter"
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <form>
                                            <div className="set-max-height-400">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                <input
                                                                    name="targeting-table-select-all"
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={selectAllchecked}
                                                                    id="mastercheck"
                                                                    onChange={(e) => onMasterCheck(e)}
                                                                />
                                                            </th>
                                                            <th scope="col">Column Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dropdownDataFilter.map((list, i) => (
                                                            <tr
                                                                key={list.keyName}
                                                                className={list.checked ? "selected" : ""}
                                                            >
                                                                <th scope="row">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={list.checked}
                                                                        className="form-check-input Selected-filter"
                                                                        onChange={(e) =>
                                                                            onItemCheck(i, e, list.selected)
                                                                        }
                                                                        name={list.keyName}
                                                                    />
                                                                </th>
                                                                <td>{list.displayName}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <hr />
                                            <div className="footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-default"
                                                    onClick={cancelDropdown}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary" // aaa
                                                    onClick={applyColumns}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="filter-item export-link-container">
                                    <p>
                                        <i>
                                            <img
                        src={ExportIcon}
                        alt="filter icon"
                        onClick={handleExportData}
                      />
                                        </i>
                                        {/* <button className="export-btn" onClick={handleExportData}>
                      Export{" "}
                    </button> */}
                                        <span onClick={handleExportData}>Export</span>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {!loading ? (
                <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                        rows={rows}
                        //columns={columns}
                        // aaa
                        columns={columns.filter(
                            (col) => hiddenColumns.includes(col.field)
                        )}
                        checkboxSelection={isBulkOperationsEnabled}
                        onRowSelectionModelChange={(id) => {
                            const selectedIDs = new Set(id);
                            const selectedRows = rows.filter((row: any) =>
                                selectedIDs.has(row.id)
                            );
                            console.log("idssss" + id);

                            setSelectedRows(id);
                            console.log("selected" + selectedRowid);
                        }}
                        hideFooter={true}
                        rowHeight={40}
                    />
                    <div className="custom-table-footer">
                        <Row>
                            <Col md={5}>
                                <form className="table-footer-left">
                                    <span>Show </span>
                                    <label>
                                        <select
                                            className="form-select"
                                            defaultValue={perPage}
                                            onChange={(event) => applyPageSize(event)}
                                        >
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="150">150</option>
                                            <option value="200">200</option>
                                        </select>
                                    </label>
                                    <span> of {totalRow} total entries</span>
                                </form>
                            </Col>
                            <Col md={7}>
                                <div className="table-footer-right">
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={lastPage}
                                            page={activePage}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChange}
                                        />
                                    </Stack>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            ) : (
                <div className="loading-container">
                    <div className="loading-text">
                        <span className="logicon">
                            <img src={LogoIcon} alt="logo-small"></img>
                        </span>
                        <span>L</span>
                        <span>O</span>
                        <span>A</span>
                        <span>D</span>
                        <span>I</span>
                        <span>N</span>
                        <span>G</span>
                    </div>
                </div>
            )}
        </div>
    );
}
export default TargetingTable;
