import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import InfoButton from "../Common/InfoButton";
import MoreActionIcon from "../../assets/images/icons/more-action-icon.svg";
import DashboardService from "../../services/DashboardService";
import NumberFormatter from "../Common/NumberFormatter";

const RoyaltiesGross = ({ filters }) => {
  const [loading, setLoading] = useState(true);
  const [isCurrecncy, setIsCurrecncy] = useState("$");
  const [state, setState] = useState({
    royaltiesNet: "$0.0",
    royaltiesGross: "$0.0",
    royaltiesGrowthRate: "$0.0",
    adSpend: "$0.0",
    firstBarWidth: "0%",
    secondBarWidth: "0%",
  });

  function calculateBarWidth(value, total) {
    let percentageVal = (value * 100) / total;
    if (percentageVal > 100) percentageVal = 100;
    if (percentageVal < 0) percentageVal = 0;
    return percentageVal + "%";
  }
  function getMetricByName(metricsArray, metricName) {
    return metricsArray.find((metric) => metric.name === metricName);
  }

  useEffect(() => {
    const countryCode = localStorage.getItem("countryCode");
    switch (countryCode) {
      case "US":
      case "AU":
      case "CA":
        setIsCurrecncy("$");
        break;
      case "FR":
      case "DE":
      case "ES":
      case "IT":
        setIsCurrecncy("€");
        break;
      case "GB":
        setIsCurrecncy("£");
        break;
      default:
        setIsCurrecncy("$");
    }

    const fetchMetricsList = async () => {
      setLoading(true);
      try {
        var request = {
          globalFilters: filters,
          metricName: "",
        };
        const fetchedMetricsList = await DashboardService.getMetricsData(
          request
        );
        if (fetchedMetricsList) {
          //setMetricsList(fetchedMetricsList);
          await calculateMetricsData(fetchedMetricsList);
        }
      } catch (error) {
        console.error("Error fetching metrics list:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchMetricsList();
  }, [filters]);

  // Fetch data when the component mounts or filters change
  const calculateMetricsData = async (metricList) => {
    setLoading(true);
    try {
      const royaltiesGross = getMetricByName(
        metricList,
        "Royalties_TotalGross"
      );
      const royaltiesNet = getMetricByName(metricList, "Royalties_TotalNet");
      const adSpend = getMetricByName(metricList, "Spend");
      if (royaltiesGross && adSpend) {
        // Update state with the fetched data
        let metrics = {
          royaltiesNet: royaltiesNet.sum,
          royaltiesGross: royaltiesGross.sum,
          royaltiesGrowthRate: royaltiesGross.growthRate,
          adSpend: adSpend.sum,
        };

        const total = metrics.royaltiesNet + metrics.adSpend;
        const firstBarWidth = total
          ? calculateBarWidth(metrics.royaltiesNet, total)
          : "0%";
        const secondBarWidth = total
          ? calculateBarWidth(metrics.adSpend, total)
          : metrics.adSpend
          ? "100%"
          : "0%";

        setState({
          royaltiesNet: metrics.royaltiesNet,
          royaltiesGross: metrics.royaltiesGross,
          royaltiesGrowthRate: metrics.royaltiesGrowthRate,
          adSpend: metrics.adSpend,
          firstBarWidth,
          secondBarWidth,
        });
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="widget-container height-220 p-10">
      <div className="widget-header">
        <Row class="row">
          <Col md={9} className="widget-select-container">
            <div className="input-group filter">
              <div className="d-flex align-items-center">
                <div
                  style={{
                    color: "#111827",
                    fontSize: "14px",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "600",
                    lineHeight: "21px",
                    wordWrap: "break-word",
                    paddingRight: "10px",
                  }}
                >
                  Royalties
                </div>
                <span className="gray-text-m">[Gross]</span>
              </div>
              <InfoButton infoText="Royalties [Gross]" />
            </div>
          </Col>
          <Col md={3} className="p-0">
            <div className="more-action-icon">
              <img src={MoreActionIcon} alt="more" />
            </div>
          </Col>
        </Row>
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            height: "100%",
            marginTop: "70px",
          }}
          className="horizontalBarChart"
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <br></br>
          <div className="loading ms-4">Loading...</div>
        </div>
      ) : (
        <>
          <div
            className="widget-body"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Row
              style={{
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              <Col md={7}>
                <div className="widget-footer-title">
                  <div
                    style={{
                      color: "#111827",
                      fontSize: "48px",
                      fontFamily: "Plus Jakarta Sans",
                      fontWeight: "700",
                      lineHeight: "52.80px",
                    }}
                  >
                    {isCurrecncy}
                    <NumberFormatter
                      value={state.royaltiesGross.toFixed(2)}
                    ></NumberFormatter>
                  </div>
                </div>
              </Col>
              <Col md={5} className="align-end">
                <div
                  className="widget-status-container up"
                  style={{
                    marginTop: "15px",
                  }}
                >
                  {state.royaltiesGrowthRate}%
                </div>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "10px",
              }}
            >
              <Col md={6}>
                <div style={{ marginLeft: "10px" }}>
                  <div
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "4px",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          background: "#00D26E",
                          borderRadius: "9999px",
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        color: "#6B7280",
                        fontSize: "14px",
                        fontFamily: "Plus Jakarta Sans",
                        fontWeight: 500,
                        lineHeight: "21px",
                        wordWrap: "break-word",
                      }}
                    >
                      Royalties
                    </div>
                    <div
                      style={{
                        color: "#9CA3AF",
                        fontSize: "14px",
                        fontFamily: "Plus Jakarta Sans",
                        fontWeight: 500,
                        lineHeight: "21px",
                        wordWrap: "break-word",
                      }}
                    >
                      [Net]
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div style={{ marginRight: "10px", textAlign: "end" }}>
                  <div
                    style={{
                      width: "86px",
                      height: "21px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "4px",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          left: "3px",
                          top: "3px",
                          position: "absolute",
                          background: "#374151",
                          borderRadius: "9999px",
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        color: "#6B7280",
                        fontSize: "14px",
                        fontFamily: "Plus Jakarta Sans",
                        fontWeight: 500,
                        lineHeight: "21px",
                        wordWrap: "break-word",
                      }}
                    >
                      Ad Spend
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    padding: "0px 10px 10px 10px",
                  }}
                >
                  <span
                    style={{
                      width: state.firstBarWidth,
                      display: "inline-flex",
                    }}
                  >
                    <div
                      style={{
                        background: "#00D26E",
                        borderRadius: "16px",
                        height: "10px",
                        width: "100%",
                        margin: "5px",
                      }}
                    ></div>
                  </span>

                  {/* Bar2 */}
                  <span
                    style={{
                      width: state.secondBarWidth,
                      display: "inline-flex",
                    }}
                  >
                    <div
                      style={{
                        background: "#374151",
                        borderRadius: "16px",
                        height: "10px",
                        width: "100%",
                        margin: "5px",
                      }}
                    ></div>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="widget-footer">
            <Row>
              <Col>
                <div
                  class="mx-2"
                  style={{
                    color: "#6B7280",
                    fontSize: "14px",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: 600,
                    lineHeight: "21px",
                    wordWrap: "break-word",
                  }}
                >
                  {isCurrecncy}
                  <NumberFormatter
                    value={state.royaltiesNet.toFixed(2)}
                  ></NumberFormatter>
                </div>
              </Col>
              <Col>
                <div
                  class="mx-2"
                  style={{
                    textAlign: "right",
                    color: "#6B7280",
                    fontSize: "14px",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: 600,
                    lineHeight: "21px",
                    wordWrap: "break-word",
                  }}
                >
                  {isCurrecncy}
                  <NumberFormatter
                    value={state.adSpend.toFixed(2)}
                  ></NumberFormatter>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default RoyaltiesGross;
