import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import "./bestSeller.css";
import LogoIcon from "../../../assets/brand/logo-icon.svg";
import Image1 from "../../../assets/images/Image-1.png";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import InfoButton from "../../../components/Common/InfoButton";
import DashboardService from "../../../services/DashboardService";
export default class BestSeller extends Component {

    state = {
        bestSellerData: [],
        apiLoading: false,
        lastPage: 1
    };

    componentDidMount(): void {
        this.getBestSellerData();
    }
    getBestSellerData = async () => {
        this.setState({ apiLoading: true });
        const responceData = await DashboardService.getBestSellerData();
        this.setState({ bestSellerData: responceData });
        this.setState({ apiLoading: false });
    };
    handleChange = (event, value: number) => {
        this.setState({ setcurrPage: value });
    };
    render() {
        return (
            <div className="widget-container px-2">
                <Row>
                    <Col>
                        <div className="top-seller-title">
                            Top Bestseller Book
                            <div className="dropdown">
                                <select
                                    className="dropdown-content"
                                    defaultValue={"DEFAULT"}
                                >
                                    <option
                                        value="DEFAULT"
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            whiteSpace: "nowrap", // Ensure the span stays on the same line
                                        }}
                                    >
                                        Royalties
                                        <span className="gray-text-m"> [Total Net] </span>
                                    </option>
                                </select>
                                <div className="info icon">
                                    <InfoButton infoText="Click through rate" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div>
                    {!this.state.apiLoading ? (
                        <>
                            {
                                this.state.bestSellerData &&
                                <Row>
                                    {this.state.bestSellerData.map((list: any, i) => (
                                        <Col>
                                            <div className="top-seller-widget mt-2" key={i}>
                                                <Row>
                                                    <Col>
                                                        {list.bookCover == null ? (
                                                            <img src={Image1} alt="image1" />
                                                        ) : (
                                                            <img src={list.bookCover} alt="image1" />
                                                        )}
                                                    </Col>
                                                    <Col className="p-0">
                                                        <span className="tag-info">#{i + 1} Bestseller</span>
                                                        <div className="top-seller-cont-title">
                                                            {list.bookName}
                                                        </div>

                                                        {list.grossRoyalties == null ? (
                                                            <p>Gross Royalties:$0</p>
                                                        ) : (
                                                            <p>Gross Royalties:${list.grossRoyalties}</p>
                                                        )}
                                                        {list.spending == null ? (
                                                            <p> Spending:$0</p>
                                                        ) : (
                                                            <p> Spending:${list.spending}</p>
                                                        )}
                                                        {list.netRoyalties == null ? (
                                                            <p>Net Royalties:$0</p>
                                                        ) : (
                                                            <p>Net Royalties:${list.netRoyalties}</p>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    ))}
                                    <div className="custom-table-footer">
                                        <Row>
                                            <Col md={12}>
                                                <div className="table-footer-right">
                                                    <Stack spacing={2}>
                                                        <Pagination
                                                            count={this.state.lastPage}
                                                            variant="outlined"
                                                            shape="rounded"
                                                            onChange={this.handleChange}
                                                        />
                                                    </Stack>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Row>

                            }
                        </>
                    ) : (
                        <div className="loading-container bestSellerLoading mb-2">
                            <div className="loading-text">
                                <span className="logicon">
                                    <img src={LogoIcon} alt="logo-small"></img>
                                </span>
                                <span>L</span>
                                <span>O</span>
                                <span>A</span>
                                <span>D</span>
                                <span>I</span>
                                <span>N</span>
                                <span>G</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        );
    }
}
