import React, { Component, RefObject } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Col, Row } from "react-bootstrap";
import Navbar from "../Navbar/navbar";
import "./amazonConnection.css";
import AmazonConnectionTable, { AmazonConnectionTableRef } from "../Table/amazonConnectionTable";

interface AmazonConnectionProps {}

interface AmazonConnectionState {
  amazonConnectionTableRef: RefObject<AmazonConnectionTableRef>;
}

export default class AmazonConnection extends Component<AmazonConnectionProps, AmazonConnectionState> {
  constructor(props: AmazonConnectionProps) {
    super(props);
    this.state = {
      amazonConnectionTableRef: React.createRef<AmazonConnectionTableRef>()
    };
  }

  handleButtonClick = () => {
    console.log('Button clicked in AmazonConnection');
    this.state.amazonConnectionTableRef.current?.handleButtonClick();
  };

  render() {
    return (
      <DashboardLayout>
        <div className="amazon-conection mt-2 container-fluid">
          <Row>
            <Col md={2}>
              <Navbar />
            </Col>
            <Col md={10}>
              <div className="amazonConnectionCard  p-4">
                <div className="amazonConectionHeading">
                  <h2>Amazon Connection</h2>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleButtonClick}
                  >
                    Add Merchant
                  </button>
                </div>
                <Row>
                  <Col md={12}>
                  <AmazonConnectionTable ref={this.state.amazonConnectionTableRef} />

                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </DashboardLayout>
    );
  }
}
