import React, { Component, useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import InfoButton from '../Common/InfoButton';
import MoreActionIcon from '../../assets/images/icons/more-action-icon.svg';
import DashboardService from '../../services/DashboardService';
import Chart from 'react-apexcharts';
import DynamicWidthDropdown from '../Common/DynamicWidthDropdown/DynamicWidthDropdown';
import MasterDataService from '../../services/MasterDataService';
interface MultiSeriesAreaChartProps {
    defaultMetric1: string;
    defaultMetric2: string;
    canSwitch: bool;
    filters: any; // Replace with the actual type for your filters
}

const MultiSeriesAreaChart: React.FC<MultiSeriesAreaChartProps> = ({ defaultMetric1, defaultMetric2, canSwitch, filters }) => {
    const [loading, setLoading] = useState(true);
    function getChartData(selectedMetrics) {
        let series = [];
        let categories = [];
        let yaxis = [];
        for (var i = 0; i < selectedMetrics.length; i++) {
            if (metricsList && metricsList.length > 0) {
                var metricData = getMetricByName(metricsList, selectedMetrics[i]);
                const sortedData = [...metricData.data].sort((a, b) => new Date(a.date) - new Date(b.date));
                if (i === 0) {
                    categories = sortedData.map((item) => {
                        const dateObject = new Date(item.date);
                        const formattedDate = dateObject.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' });
                        return formattedDate;
                    });
                    // categories = sortedData.map((item) => item.date);
                }
                series.push({ name: selectedMetrics[i], type: 'area', data: sortedData.map((item) => item.value) });
            }
            else {
                series.push({ name: selectedMetrics[i], data: [] });
            }

            yaxis.push({ title: { text: selectedMetrics[i] }, opposite: i > 0 });

        }

        const seriesColors = [
            '#00D26E',
            '#374151',
            '#008FFB', // Blue
            '#00E396', // Green
            '#FEB019', // Yellow
            '#FF4560', // Red
            '#775DD0', // Purple
            '#546E7A', // Grey
            '#26a69a', // Teal
            '#D10CE8', // Pink
            '#6610F2', // Indigo
            '#FFD700', // Gold
        ];

        let result = {
            options: {
                chart: {
                    id: "multi_series_area_chart",
                    toolbar: {
                        show: false
                    },
                },
                xaxis: {
                    categories: categories,
                    tickAmount: 8,
                    tickPlacement: 'on',
                },
                yaxis: yaxis,
                stroke: {
                    curve: 'straight',
                    width: 1
                },
                dataLabels: {
                    enabled: false
                },
                colors: seriesColors
            },
            series: series
        };

        return result;
    }

    const [metaMetricList, setMetaMetricList] = useState([]);
    const [metricsList, setMetricsList] = useState([]);
    const [selectedMetric1, setSelectedMetric1] = useState(defaultMetric1);
    const [selectedMetric2, setSelectedMetric2] = useState(defaultMetric2);
    const chartData = getChartData([defaultMetric1, defaultMetric2]);

    const [chartSeries, setChartSeries] = useState(chartData.series);
    const [chartOptions, setChartOptions] = useState(chartData.options);
    const [selectedMetric1Data, setSelectedMetric1Data] = useState({
        name: '',
        unit: '',
        description: '',
        value: 0.0,
        average: 0.0,
        sum: 0.0,
        min: 0.0,
        max: 0.0,
        growthRate: 0.0,
        data: []
    });
    const [selectedMetric2Data, setSelectedMetric2Data] = useState({
        name: '',
        unit: '',
        description: '',
        value: 0.0,
        average: 0.0,
        sum: 0.0,
        min: 0.0,
        max: 0.0,
        growthRate: 0.0,
        data: []
    });

    function getMetricByName(metricsArray, name) {
        return metricsArray.find(metric => metric.name === name);
    }

    const fetchMetaData = async () => {
        try {
            const metaData = await MasterDataService.getMetaData();
            if (metaData.Metric) {
                setMetaMetricList(metaData.Metric);
                setSelectedMetric1Data(getMetricByName(metaData.Metric, selectedMetric1));
                setSelectedMetric2Data(getMetricByName(metaData.Metric, selectedMetric2));
            }
        } catch (error) {
            console.error('Error fetching metrics list:', error);
        }
        finally {
        }
    };

    const fetchMetricsList = async () => {
        try {
            var request = {
                globalFilters: filters,
                metricName: ''
            };
            const fetchedMetricsList = await DashboardService.getMetricsData(request);

            // Update the state with the fetched metrics list
            setMetricsList(fetchedMetricsList);
        } catch (error) {
            console.error('Error fetching metrics list:', error);
        }
        finally {
        }
    };

    const fetchData = async () => {
        try {
            const metricChartData = getChartData([selectedMetric1, selectedMetric2]);
            setChartSeries(metricChartData.series);
            setChartOptions(metricChartData.options);

        } catch (error) {
            console.error('Error fetching metric data:', error);
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        setLoading(true);
        fetchMetaData();
    }, []);

    useEffect(() => {
        setLoading(true);
        fetchMetricsList();
    }, [filters]);

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [filters, metaMetricList, metricsList, selectedMetric1, selectedMetric2]);

    const handleMetricChange1 = (event) => {
        setSelectedMetric1(event.target.value);
    };

    const handleMetricChange2 = (event) => {
        setSelectedMetric2(event.target.value);
    };

    return (
        <div className="widget-container height-350px">
            <div className="widget-header">
                <Row>
                    <Col md={5} className="widget-select-container dot-con">
                        <span className="dot bg-green"></span>
                        <div className="input-group filter d-flex align-items-center">
                            <div
                                style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    display: 'inline-flex'
                                }}
                            >
                                {
                                    !canSwitch ? (
                                        !selectedMetric1Data &&
                                        (
                                            <>
                                                <div style={{
                                                    color: '#111827',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '600',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word',
                                                    paddingRight: '10px'
                                                }}>{selectedMetric1Data.title}</div>
                                                {selectedMetric1Data.subTitle && (<span className='gray-text-m'>[{selectedMetric1Data.subTitle}]</span>)}
                                                <InfoButton infoText={selectedMetric1Data.description} />
                                            </>
                                        )
                                    )
                                        :
                                        (
                                            metaMetricList.length > 0 && (
                                                <DynamicWidthDropdown
                                                    options={metaMetricList}
                                                    selectedItem={selectedMetric1}
                                                    handleSelectChange={handleMetricChange1}
                                                ></DynamicWidthDropdown>
                                            )
                                        )
                                }

                            </div>
                        </div>
                    </Col>
                    <Col md={7} className="p-0">
                        <Row>
                            <Col md={10}>
                                <div className="widget-select-container pull-right dot-con">
                                    <span className="dot bg-black"></span>
                                    <div className="input-group filter pull-right">
                                        <div
                                            style={{
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                display: 'inline-flex'
                                            }}
                                        >
                                            {
                                                !canSwitch ? (
                                                    !selectedMetric2Data &&
                                                    (
                                                        <>
                                                            <div style={{
                                                                color: '#111827',
                                                                fontSize: '14px',
                                                                fontFamily: 'Plus Jakarta Sans',
                                                                fontWeight: '600',
                                                                lineHeight: '21px',
                                                                wordWrap: 'break-word',
                                                                paddingRight: '10px'
                                                            }}>{selectedMetric2Data.title}</div>
                                                            {selectedMetric2Data.subTitle && (<span className='gray-text-m'>[{selectedMetric2Data.subTitle}]</span>)}
                                                            <InfoButton infoText={selectedMetric2Data.description} />
                                                        </>
                                                    )
                                                )
                                                    :
                                                    (
                                                        metaMetricList.length > 0 && (
                                                            <DynamicWidthDropdown
                                                                options={metaMetricList}
                                                                selectedItem={selectedMetric2}
                                                                handleSelectChange={handleMetricChange2}
                                                            ></DynamicWidthDropdown>
                                                        )
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="more-action-icon">
                                    <img src={MoreActionIcon} alt="info circle icon" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="app">
                <div className="row">
                    <div className="mixed-chart">
                        {
                            loading ?
                                (
                                    <div
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            height: "100%",
                                            marginTop: "140px",
                                        }}
                                        className="horizontalBarChart"
                                    >
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        <br></br>
                                        <div className="loading ms-4">Loading...</div>
                                    </div>
                                )
                                :
                                (chartSeries && chartSeries.length > 0) ?
                                    (
                                        <Chart
                                            id="multi_series_area_chart"
                                            options={chartOptions}
                                            series={chartSeries}
                                            type="area"
                                            width="100%"
                                            height="300px"
                                        />
                                    )
                                    :
                                    (
                                        < div style={{
                                            width: "100%",
                                            textAlign: "center",
                                            height: "100%",
                                            marginTop: "140px",
                                        }}
                                        >
                                            No Data
                                        </div>
                                    )
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default MultiSeriesAreaChart;
