import React, { useState, useEffect } from 'react';
import './DateRangeFilter.css';
import { DatePicker, DateRangePicker } from "rsuite";
import CalenderIcon from "../../../assets/images/icons/calendar-icon.svg";

const DateRangeFilter = ({ options, value, onChange }) => {
    const [dropdownWidth, setDropdownWidth] = useState('220px');
    const getDefaultDateRange = () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 30);
        return [startDate, endDate];
    };
    const [dateRange, setDateRange] = useState(getDefaultDateRange());
    const [selectedDateRanges, setSelectedDateRanges] = useState(value | {
        dateRange: "Last30Days",
        startDate: null,
        endDate: null
    });
    const [selectedItem, setSelectedItem] = useState({
        "name": "Last 30 days",
        "value": "Last30Days",
        "mostCommonlyUsed": true
    });

    const [dropDownWidth, setDropDownWidth] = useState(200);

    const [showDropDownMenu, setShowDropDownMenu] = useState(false);
    const handleOnChange = (event, item) => {
        setSelectedItem(item);

        let dateRanges = {
            dateRange: item.value,
            startDate: null,
            endDate: null
        };
        setDropdownWidth(item.value === 'Custom' ? '293px' : '200px');
        if (item.value === 'Custom') {
            const defaultDateRanges = getDefaultDateRange();
            dateRanges.startDate = defaultDateRanges[0];
            dateRanges.endDate = defaultDateRanges[1];
        }
        setSelectedDateRanges(dateRanges);
        setShowDropDownMenu(false);
        onChange(dateRanges);
    }

    const handleDateRangeChange = async (selectedDates, event) => {
        if (selectedDates && selectedDates.length > 0) {
            const dateRanges = {
                dateRange: 'Custom',
                startDate: selectedDates[0],
                endDate: selectedDates[1]
            };
            setDateRange(selectedDates);
            setSelectedDateRanges(dateRanges);
            setShowDropDownMenu(false);
            onChange(dateRanges);
        }
    }

    const showHideDropDownMenu = (event) => {
        setShowDropDownMenu(!showDropDownMenu);
    }

    useEffect(() => {
        if (options) {
            setSelectedItem(options.find((item) => item.value === value.dateRange));
        }
    }, [value, options]);

    useEffect(() => {
        if (options && selectedDateRanges) {
            setSelectedItem(options.find((item) => item.value === selectedDateRanges.dateRange));
        }
    }, [selectedDateRanges, options]);

    return (
        <div className="DateRangeFilter">
            <div className="time-line" style={{ width: dropdownWidth }}>
                <div className={`input-group ${showDropDownMenu ? 'clicked' : ''}`} style={{ width: dropdownWidth }}>
                    <div className="custom-dropdown" style={{ width: dropdownWidth }}>
                        <span className="input-group-text no-arrow"
                            style={{ width: dropdownWidth }}
                            onClick={showHideDropDownMenu}
                            id="inputGroup-sizing-sm">
                            <div className="dropdown-header custom-dropdown-header">
                                <span className="date-left-icon">
                                    <img src="/static/media/calendar-icon.a31c32fbc49583ad1746b3e0063c0ded.svg"
                                        alt="calendar icon"
                                        className={
                                            showDropDownMenu
                                                ? 'bright-icon'
                                                : 'normal-icon'
                                        }
                                    />
                                </span>
                                <span className="date-text-con">
                                    <span className="dateselection date-text-con">
                                        {
                                            selectedItem ?
                                                (
                                                    selectedItem.value === 'Custom' ?
                                                        (
                                                            <span onClick={(event) => {
                                                                event.stopPropagation();
                                                            }}>
                                                                <DateRangePicker
                                                                    showOneCalendar
                                                                    placeholder="Select a timeline"
                                                                    value={dateRange}
                                                                    onChange={handleDateRangeChange}
                                                                    format="yyyy-MM-dd"
                                                                    ranges={[]}
                                                                />
                                                            </span>
                                                        )
                                                        :
                                                        (
                                                            <>{selectedItem.name}</>
                                                        )
                                                )
                                                :
                                                (
                                                    <>Select a time line</>
                                                )
                                        }
                                    </span>
                                </span>
                                <span
                                    className={`arrow ${showDropDownMenu
                                        ? 'up greenArrow'
                                        : 'down'
                                        }`}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <path d="M5 13L10 8L15 13" stroke={
                                            showDropDownMenu
                                                ? '#00B470'
                                                : '#6b7280'
                                        }
                                            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </div>
                        </span>
                        {
                            showDropDownMenu && options && options.length > 0 &&
                            (
                                <div className="dashboard-calendar dropdown-options">
                                    {
                                        options.map((item, i) => (
                                            <div className="dropdown-item"
                                                onClick={(event) => handleOnChange(event, item)}
                                            >
                                                {item.name}
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DateRangeFilter;
