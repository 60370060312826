import React, { Component } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Row, Col } from "react-bootstrap";
import "./index.css";

import LinkIcon from "../../assets/images/icons/link-icon.svg";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import isAfter from "date-fns/isAfter";
import format from "date-fns/format";
import MarketplaceTable from "./table/marketplace-table";
import AdGroupTable from "./table/adgroup-table";
import CampaignTable from "./table/campaign-table";
import PortfoliosTable from "./table/portfolio-table";
import ProductsTable from "./table/products-table";
import TargetingTable from "./table/targeting-table";
import SearchTermsTable from "./table/searchterms-table";
import MasterDataService from "../../services/MasterDataService";
import DateRangeFilter from "../../components/Common/DateRangeFilter/DateRangeFilter";

class AdsCompaignManager extends Component {
  filterData = [];
  current = new Date();
  startDate = new Date(new Date().setDate(this.current.getDate() - 30));
  endDate = new Date(); // Updated this line
  state = {
    globalFilterFromData: [],
    searchKeyFilter: "",
    globalFilterDateRange: {
      dateRange: "Custom", // Set the default date range to "Custom"
      startDate: (() => {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 29); // Subtract 29 to get the start date of the last 30 days
        return startDate;
      })(),
      endDate: new Date(), // Set the default end date to today
    },
    metaData: [],
    lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
    selectedStartDate: null,
    selectedEndDate: null,
    activeTab: "marketplace",
    filters: {
      pageNumber: 1,
      pageSize: 50,
      sortOptions: [],
      columnFilters: [],
      globalFilters: {
        searchText: "",
        authors: [],
        bookFormats: [],
        dateRanges: {
          dateRange: "Last30Days",
          startDate: null,
          endDate: null,
        },
        titles: [],
        campaigns: [],
        platforms: [],
        marketPlaces: [],
        adTypes: [],
        advancedFilters: [],
      },
    },
    dateRangeOptions: [],
  };

  constructor(props) {
    super(props);
    //this.onChangeDateRangeSubmitGlobal =
    //this.onChangeDateRangeSubmitGlobal.bind(this);

    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 30);
    this.startDate.setDate(this.endDate.getDate() - 30);
  }

  componentDidMount() {
    this.getMetaData();
  }

  getMetaData = async () => {
    try {
      const metaData = await MasterDataService.getMetaData();
      if (metaData) {
        this.setState({ dateRangeOptions: metaData?.dateRanges });
        this.setState({ metaData: metaData });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  handleTabSelect = (tab) => {
    this.setState({ activeTab: tab });
  };

  //handleNvEnter = (event) => {
  //    console.log("Nv Enter:", event);
  //};

  //handleCallback = (childData) => {
  //    this.setState({ globalFilterFromData: childData });
  //    this.filterData = childData;
  //};

  //handleKeyDown = (event) => {
  //    if (event.key === "Enter") {
  //        this.setState({ searchKeyFilter: event.target.value });
  //        let updatedFilters = {
  //            ...this.state.filters
  //        };
  //        updatedFilters.globalFilters.searchText = event.target.value;
  //        this.setState({ filters: updatedFilters });
  //        event.preventDefault();
  //    }
  //};

  handleDateRangeFilterChange = (value) => {
    let updatedFilters = {
      ...this.state.filters,
    };

    updatedFilters.globalFilters.dateRanges = value;

    this.setState({ filters: updatedFilters });
  };

  //cancelDropdown = () => {
  //    const eleRm = document.getElementById("dropdownCon");
  //    if (eleRm) {
  //        eleRm.classList.remove("open");
  //    }

  //    const eleRm2 = document.getElementById("dropdownCon2");
  //    if (eleRm2) {
  //        eleRm2.classList.remove("open");
  //    }
  //};

  //openDropdown(e) {
  //    const eleRm = document.getElementById("dropdownCon2");
  //    if (eleRm) {
  //        eleRm.classList.remove("open");
  //    }

  //    const ele = document.getElementById("dropdownCon");
  //    if (ele) {
  //        ele.classList.add("open");
  //    }
  //}

  //openDropdown2(e) {
  //    const eleRm = document.getElementById("dropdownCon");
  //    if (eleRm) {
  //        eleRm.classList.remove("open");
  //    }

  //    const ele = document.getElementById("dropdownCon2");
  //    if (ele) {
  //        ele.classList.add("open");
  //    }
  //}
  //onChangeDateRangeSubmitGlobal = async (selectedDates, event) => {
  //    try {
  //        let dateRangeLabel = event?.target?.innerText || "Custom";

  //        if (dateRangeLabel === "OK") {
  //            dateRangeLabel = "Custom";
  //        }

  //        if (dateRangeLabel === "Custom" && selectedDates.length > 0) {
  //            const startDate = selectedDates[0];
  //            const endDate = selectedDates[1];

  //            // Update the state with correct structure, including dateRange
  //            this.setState({
  //                globalFilterDateRange: { dateRange: "Custom", startDate, endDate },
  //            });

  //            let advancedFilterDateRange = {
  //                dateRange: "Custom",
  //                startDate,
  //                endDate,
  //            };

  //            console.log("Advanced Filter Date Range:", advancedFilterDateRange);
  //        } else {
  //            // For non-"Custom" labels, set state without startDate and endDate
  //            this.setState({
  //                globalFilterDateRange: { dateRange: dateRangeLabel },
  //            });

  //            let advancedFilterDateRange = {
  //                dateRange: dateRangeLabel,
  //            };

  //            console.log("Advanced Filter Date Range:", advancedFilterDateRange);
  //        }
  //    } catch (error) {
  //        console.error("Error in onChangeDateRangeSubmitGlobal:", error);
  //    }
  //};

  //formatDate = (date) => {
  //    return date.toLocaleDateString("en-IN", {
  //        weekday: "long",
  //        year: "numeric",
  //        month: "long",
  //        day: "numeric",
  //    });
  //};

  renderTableForTab = () => {
    const { activeTab } = this.state;

    switch (activeTab) {
      case "marketplace":
        return (
          <MarketplaceTable
            isBulkOperationsEnabled={false}
            tabName="Marketplaces"
            filters={this.state.filters}
            metaData={this.state.metaData}
          />
        );
      case "portfolios":
        return (
          <PortfoliosTable
            isBulkOperationsEnabled={false}
            tabName="Portfolios"
            filters={this.state.filters}
            metaData={this.state.metaData}
          />
        );
      case "campaign":
        return (
          <CampaignTable
            isBulkOperationsEnabled={true}
            tabName="Campaigns"
            filters={this.state.filters}
            metaData={this.state.metaData}
          />
        );
      case "adgroup":
        return (
          <AdGroupTable
            isBulkOperationsEnabled={true}
            tabName="AdGroups"
            filters={this.state.filters}
            metaData={this.state.metaData}
          />
        );
      case "targeting":
        return (
          <TargetingTable
            isBulkOperationsEnabled={true}
            tabName="Targets"
            filters={this.state.filters}
            metaData={this.state.metaData}
          />
        );
      case "products":
        return (
          <ProductsTable
            isBulkOperationsEnabled={true}
            tabName="Products"
            filters={this.state.filters}
            metaData={this.state.metaData}
          />
        );
      case "searchterms":
        return (
          <SearchTermsTable
            isBulkOperationsEnabled={false}
            tabName="SearchTerms"
            filters={this.state.filters}
            metaData={this.state.metaData}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const startOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfThisMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const startOfLastMonth = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth(),
      1
    );
    const endOfLastMonth = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth() + 1,
      0
    );
    console.log(
      "Filter Date Range in MarketplaceTable:",
      `Date Range: ${this.state.globalFilterDateRange.dateRange}, Start Date: ${this.state.globalFilterDateRange.startDate}, End Date: ${this.state.globalFilterDateRange.endDate}`
    );
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    return (
      <DashboardLayout>
        <div className="main-cont-header">
          <Row className="page-header">
            <Col>
              <div className="main-con-page-title-container">
                <div className="title">
                  <h3 className="page-title">Campaign Manager</h3>
                </div>
                <div className="filters">
                  <style>
                    {`
      .rs-picker-daterange-header {
        margin-top: 30px;
      }
    `}
                  </style>
                  <DateRangeFilter
                    options={this.state.dateRangeOptions}
                    onChange={this.handleDateRangeFilterChange}
                    value={this.state.filters.globalFilters.dateRanges}
                  ></DateRangeFilter>
                  {/*<DateRangePicker*/}
                  {/*    showOneCalendar*/}
                  {/*    placeholder="Select a timeline"*/}
                  {/*    onChange={this.onChangeDateRangeSubmitGlobal}*/}
                  {/*    format="yyyy-MM-dd"*/}
                  {/*    defaultValue={[*/}
                  {/*        moment().subtract(29, "days").toDate(),*/}
                  {/*        new Date(),*/}
                  {/*    ]}*/}
                  {/*    ranges={[]}*/}
                  {/*/>*/}
                </div>
              </div>
            </Col>
            {/*<Col className="text-end last-sync justify-content-end">*/}
            {/*  <span className="last-sync">*/}
            {/*    <div>Last App Sync</div>*/}
            {/*    <div className="lastspan">*/}
            {/*      {" "}*/}
            {/*      {this.state.lastUpdatedDate || "Not available"}*/}
            {/*    </div>*/}
            {/*  </span>*/}
            {/*</Col>*/}
          </Row>
        </div>
        <div className="main-content-container">
          <hr />
          <div className="dashboard-container padding-lr-30 campaing-manger">
            <div className="tab-container">
              <Tabs
                activeKey={this.state.activeTab}
                onSelect={this.handleTabSelect}
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="marketplace" title="Marketplace">
                  {this.renderTableForTab()}
                </Tab>
                <Tab eventKey="portfolios" title="Portfolios">
                  {this.renderTableForTab()}
                </Tab>
                <Tab eventKey="campaign" title="Campaign">
                  {this.renderTableForTab()}
                </Tab>
                <Tab eventKey="adgroup" title="Ad Group">
                  {this.renderTableForTab()}
                </Tab>
                <Tab eventKey="targeting" title="Targeting">
                  {this.renderTableForTab()}
                </Tab>
                <Tab eventKey="products" title="Products">
                  {this.renderTableForTab()}
                </Tab>
                <Tab eventKey="searchterms" title="Search Terms">
                  {this.renderTableForTab()}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

export default AdsCompaignManager;
