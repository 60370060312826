import React, { useEffect, useState } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { Row, Col, Container, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "../../index.css";
import LinkIcon from "../../../../../assets/images/icons/link-icon.svg";
import BackArrow from "../../../../../assets/images/icons/back-arrow-icon.svg";
import ThreeDots from "../../../../../assets/images/icons/more-action-icon.svg";
import DeleteIcon from "../../../../../assets/images/icons/trash-icon.svg";
import Moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import InfoIcon from "../../../../../assets/images/icons/info-circle-icon.svg";
import same from "../../../../../assets/images/icons/same.png";
import individual from "../../../../../assets/images/icons/Individual.png";
import individualcampaign from "../../../../../assets/images/icons/individualcampaign.png";
import { getWidth } from "rsuite/esm/DOMHelper";
import { wrap } from "module";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface autoState {
  isAuto?: (isautoData: any) => void;
}
const AutoCampaign: React.FunctionComponent<autoState> = ({ isAuto }) => {
  const navigate = useNavigate();
  const params = useParams();
  const CreateFormData = {
    campaignType: "AutoCampaign",
    campaignName: "",
    asins: null,
    matchType: "SingleCampaignWithSingleAdgroup",
    placement: {
      topOfSearch: "",
      productDetailPages: "",
      restOfSearch: "",
    },
    settings: {
      portfolio: null,
      startDate: "",
      endDate: "",
      campaignBiddingStrategy: "",
      dailyBudget: "",
    },
    marketPlaces: [],
    negativeKeywordTargeting: {
      targetingLevel: "Campaign level",
      targetingType: "Negative Products",
      keywords: [],
    },
    dynamicBidding: {
      defaultBid: "",
      targetingBid: "",
    },
    targets: null,
    postToAmazon: true,
  };
  console.log(params);

  const matchTypesList = [
    { id: 1, nameid: "close", title: "Close", isChecked: true, name: "" },
    { id: 2, nameid: "loose", title: "Loose", isChecked: true, name: "" },
    {
      id: 3,
      nameid: "substitutes",
      title: "Substitutes",
      isChecked: true,
      name: "",
    },
    {
      id: 4,
      nameid: "complements",
      title: "Complements",
      isChecked: true,
      name: "",
    },
  ];
  const isDefaultError = {
    campaignBiddingStrategy: true,
    dailyBudget: true,
    targetingBid: true,
    defaultBid: true,
    marketPlaces: true,
  };
  const [iscreateForm, setIsCreateForm] = useState<any>(CreateFormData);
  const [isMatchList, setIsMatchList] = useState<any>(matchTypesList);
  const [isError, setIsError] = useState<any>(isDefaultError);
  const [isSelectedCampaign, setIsSelectedCampaign] = useState(1);
  const [isSelected, setIsSelected] = useState(null);
  const [isAdKeywords, setIsAdKeywords] = useState<any>([]);
  const [isAdKeywordType, setIsAdKeywordType] = useState("Negative exact");
  const [isAddTempAdKeywords, setIsAddTempAdKeywords] = useState("");
  const [isPortfolio, setIsPortfolio] = useState([]);
  const [isMarketPlace, setIsMarketPlace] = useState([]);
  const [isMarketPlacesSelected, setIsMarketPlacesSelected] = useState<any>([]);
  const [isTabChange, setIsTabChange] = useState("NegativeKeywords");
  const [isimgstate, setIsImgState] = useState(1);
  const [isautoData, setIsAutoData] = useState<any>([]);
  const [isDate, setIsDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  //////////////FUNCTION///////////////
  useEffect(() => {
    getMarketplaceData();
    const encodedString: any = params.asin;
    const decodedString = decodeURIComponent(encodedString);

    setIsCreateForm((prev: any) => {
      return {
        ...prev,
        asins: decodedString.split(","),
      };
    });
    if (params.id) {
      const id: any = "id";
      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          [id]: params.id,
        };
      });
      getCampainById(params.id);
    }
  }, []);

  useEffect(() => {
    getPortfolioData();
  }, [isMarketPlacesSelected]);

  const getCampainById = async (id: any) => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/AdsManager/" + id;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    });

    try {
      const responceData = await response.json();
      setIsMarketPlacesSelected(responceData.result.marketPlaces.toString());
      setIsAdKeywords(responceData.result.negativeKeywordTargeting.keywords);
      setIsSelected(responceData.result.settings.campaignBiddingStrategy);
      setIsMatchList((prev: any) => {
        return prev.map((item, index) => {
          if (item.nameid === "close") {
            return {
              ...item,
              name: responceData.result.dynamicBidding.close,
            };
          } else if (item.nameid === "loose") {
            return {
              ...item,
              name: responceData.result.dynamicBidding.loose,
            };
          } else if (item.nameid === "substitutes") {
            return {
              ...item,
              name: responceData.result.dynamicBidding.substitutes,
            };
          } else if (item.nameid === "complements") {
            return {
              ...item,
              name: responceData.result.dynamicBidding.complements,
            };
          }
          return item;
        });
      });

      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          campaignName: responceData.result.campaignName.expression,
          placement: responceData.result.placement,
          marketPlaces: responceData.result.marketPlaces,
          settings: responceData.result.settings,
          dynamicBidding: {
            ...prev.dynamicBidding,
            defaultBid: responceData.result.dynamicBidding.defaultBid,
            targetingBid: responceData.result.dynamicBidding.targetingBid,
          },

          negativeKeywordTargeting: {
            ...prev.negativeKeywordTargeting,
            keywords: responceData.result.negativeKeywordTargeting.keywords,
          },
        };
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPortfolioData = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/AdsManager/Portfolios";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        mrketplaces: isMarketPlacesSelected,
      }),
    });

    try {
      const responceData = await response.json();
      setIsPortfolio(responceData?.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getMarketplaceData = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/AdsManager/Marketplaces";
    const requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (responceData.success) {
        let result = responceData.result;
        setIsMarketPlace(result);
        // this.setState({ marketPlacesList: result });
        console.error(result);
      } else {
        // toast("Unable to create");
      }
      // setRedirect("/onboarding");
    } catch (error) {
      console.error("Error fetching data:", error);
      // toast("Error On Creating");
    }
  };
  const marketplacesList = (item: any) => {
    setIsMarketPlacesSelected(item);
    setIsCreateForm((prev: any) => {
      return {
        ...prev,
        marketPlaces: [item],
      };
    });

    // let list: any = [...isMarketPlacesSelected];
    // if (list.includes(item)) {
    //   const updatedList = list.filter((itemList: any) => itemList !== item);
    //   setIsMarketPlacesSelected(updatedList);
    //   setIsCreateForm((prev: any) => {
    //     return {
    //       ...prev,
    //       marketPlaces: updatedList,
    //     };
    //   });
    // } else {
    //   list.push(item);
    //   setIsMarketPlacesSelected(list);
    //   setIsCreateForm((prev: any) => {
    //     return {
    //       ...prev,
    //       marketPlaces: list,
    //     };
    //   });
    // }
  };

  const marketplaces = isMarketPlace.map((item: any, index) => (
    <div
      key={index}
      style={{ cursor: "pointer" }}
      className={`marketplace-item ${
        isMarketPlacesSelected === item.countryCode
          ? "marketplace-selected"
          : null
      }`}
      onClick={() => marketplacesList(item.countryCode)}
    >
      {item.countryCode === "US" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/323/323310.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.countryCode}
          </div>
        </>
      ) : item.countryCode === "CA" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/197/197430.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.countryCode}
          </div>
        </>
      ) : item.countryCode === "ES" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/197/197593.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.countryCode}
          </div>
        </>
      ) : item.countryCode === "FR" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/197/197560.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.countryCode}
          </div>
        </>
      ) : item.countryCode === "DE" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/197/197571.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.countryCode}
          </div>
        </>
      ) : item.countryCode === "IT" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/323/323325.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.countryCode}
          </div>
        </>
      ) : item.countryCode === "AU" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/323/323367.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.countryCode}
          </div>
        </>
      ) : (
        <> {item.countryCode}</>
      )}
    </div>
  ));
  const handleChange = (e: any) => {
    setIsCreateForm((prev: any) => {
      const campaignName = e.target.value;
      return {
        ...prev,
        campaignName: campaignName,
      };
    });
  };
  const handleChanges = (value: any) => {
    if (value === 0) {
      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          matchType: "MultipleCampaigns",
        };
      });
    }
    setIsSelectedCampaign(value);
  };
  const isimgChange = (value: any) => {
    setIsImgState(value);
    if (value === 2) {
      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          matchType: "SingleCampaignWithMultipleAdgroup",
        };
      });
    } else {
      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          matchType: "SingleCampaignWithSingleAdgroup",
        };
      });
    }
  };

  const placementChange = (e: any, type: any) => {
    const value = e.target.value;
    if (value < 900) {
      if (type === "topOfSearch") {
        setIsCreateForm((prev: any) => {
          const value = e.target.value;
          return {
            ...prev,
            placement: {
              ...prev.placement,
              topOfSearch: parseInt(value),
            },
          };
        });
      } else if (type === "productDetailPages") {
        setIsCreateForm((prev: any) => {
          const value = e.target.value;
          return {
            ...prev,
            placement: {
              ...prev.placement,
              productDetailPages: parseInt(value),
            },
          };
        });
      } else {
        setIsCreateForm((prev: any) => {
          const value = e.target.value;
          return {
            ...prev,
            placement: {
              ...prev.placement,
              restOfSearch: parseInt(value),
            },
          };
        });
      }
    }
  };
  const handleOptionClick = (value: any) => {
    setIsSelected(value);
    setIsCreateForm((prev: any) => {
      return {
        ...prev,
        settings: {
          ...prev.settings,
          campaignBiddingStrategy: value,
        },
      };
    });
  };
  const settingChange = (e: any, type: any) => {
    if (type === "dailyBudget") {
      if (e.target.value < 900) {
        setIsCreateForm((prev: any) => {
          return {
            ...prev,
            settings: {
              ...prev.settings,
              dailyBudget: parseFloat(e.target.value),
            },
          };
        });
      }
    } else {
      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          settings: {
            ...prev.settings,
            portfolio: e.target.value,
          },
        };
      });
    }
  };
  const handleChangeDate = (type: any, date: any) => {
    if (type === "start") {
      setIsCreateForm((prev: any) => {
        const currentDate = date;
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");
        return {
          ...prev,
          settings: {
            ...prev.settings,
            startDate: `${year}-${month}-${day}`,
          },
        };
      });
      setIsDate((prev: any) => {
        return {
          ...prev,
          startDate: date,
        };
      });
    } else {
      setIsCreateForm((prev: any) => {
        const currentDate = date;
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");
        return {
          ...prev,
          settings: {
            ...prev.settings,
            endDate: `${year}-${month}-${day}`,
          },
        };
      });
      setIsDate((prev: any) => {
        return {
          ...prev,
          endDate: date,
        };
      });
    }
  };
  const handleTabSelect = (eventkey: any) => {
    setIsTabChange(eventkey);
  };
  const addKeyWordType = (e: any) => {
    setIsAdKeywordType(e.target.value);
  };
  useEffect(() => {
    setIsCreateForm((prev: any) => {
      return {
        ...prev,
        negativeKeywordTargeting: {
          ...prev.negativeKeywordTargeting,
          keywords: isAdKeywords,
        },
      };
    });
  }, [isAdKeywords]);

  const adkeyWordSubmit = () => {
    let tempValues: any = {
      type:
        isTabChange === "NegativeProducts"
          ? "NegativeProducts"
          : isAdKeywordType,
      keyword: isAddTempAdKeywords,
      isDeleted: false,
    };
    setIsAdKeywords((prev: any) => {
      return [...prev, tempValues];
    });
    setIsAddTempAdKeywords("");
  };
  const removeFormFields = (id: any) => {
    setIsAdKeywords((prev: any) => {
      const updateArray = prev.map((item: any, index: number) =>
        index === id ? { ...item, isDeleted: !item.isDeleted } : item
      );
      return updateArray;
    });
  };

  const removeAllAdkeyWords = () => {
    setIsAdKeywords([]);
  };
  const keywordChange = (e: any, type: any) => {
    const value = e.target.value;
    if (value < 900) {
      if (type === "defaultBid") {
        setIsCreateForm((prev: any) => {
          return {
            ...prev,
            dynamicBidding: {
              ...prev.dynamicBidding,
              defaultBid: parseFloat(value),
            },
          };
        });
      } else {
        setIsCreateForm((prev: any) => {
          return {
            ...prev,
            dynamicBidding: {
              ...prev.dynamicBidding,
              targetingBid: parseFloat(value),
            },
          };
        });
      }
    }
  };

  const keywordChangedynamic = (e: any) => {
    const value = e.target.value;
    if (value < 900) {
      setIsMatchList((prev: any) => {
        const updateArray = prev.map((item: any, index) =>
          item.nameid === e.target.name
            ? { ...item, name: parseFloat(value) }
            : item
        );
        return updateArray;
      });
    }
  };

  const keywordChangeHandler = (e: any, id: any) => {
    setIsMatchList((prev: any) => {
      const updateArray = prev.map((item: any, index: number) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      );
      return updateArray;
    });
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    const validationCheck = {
      dailyBudget: !!iscreateForm?.settings?.dailyBudget,
      targetingBid: !!iscreateForm?.dynamicBidding?.targetingBid,
      defaultBid: !!iscreateForm?.dynamicBidding?.defaultBid,
      campaignBiddingStrategy:
        !!iscreateForm?.settings?.campaignBiddingStrategy,
      marketPlaces: iscreateForm?.marketPlaces?.length > 0,
    };
    setIsError(validationCheck);
    console.log(validationCheck);

    const errorValidation = Object.values(validationCheck).every(
      (value) => value === true
    );
    if (errorValidation) {
      let updateKeyword: any;

      if (!params.id) {
        updateKeyword = isAdKeywords.filter(
          (item: any, index) => item.isDeleted === false
        );
      } else {
        updateKeyword = isAdKeywords.filter((item, index) => item);
      }
      const updatedPlacement = {
        ...iscreateForm.placement,
        topOfSearch: iscreateForm.placement.topOfSearch || 0,
        productDetailPages: iscreateForm.placement.productDetailPages || 0,
        restOfSearch: iscreateForm.placement.restOfSearch || 0,
      };

      const updateArray = isMatchList
        .filter((item: any) => item.isChecked && item.name)
        .reduce((acc: any, item: any) => {
          return {
            ...acc,
            [item.nameid]: item.name,
          };
        }, {});

      const negativeKeywordTargeting =
        iscreateForm.negativeKeywordTargeting?.keywords?.length === 0;

      const updatedForm = {
        ...iscreateForm,
        negativeKeywordTargeting: negativeKeywordTargeting
          ? null
          : {
              ...iscreateForm.negativeKeywordTargeting,
              keywords: updateKeyword,
            },

        placement: updatedPlacement,
        dynamicBidding: {
          ...iscreateForm.dynamicBidding,
          ...updateArray,
        },
      };
      if (params.keyword) {
        if (isAuto) {
          isAuto(updatedForm);
        }
      } else {
        if (params.id) {
          await updateDataCampaign(updatedForm);
        } else {
          await postDataCampaign(updatedForm);
        }
      }
    }
    return;
  };

  const resetData = () => {
    setIsCreateForm(CreateFormData);
    setIsMatchList(matchTypesList);
    setIsSelected(null);
    setIsAdKeywords([]);
    setIsAddTempAdKeywords("");
    setIsMarketPlacesSelected(null);
  };

  const postDataCampaign = async (updateArray: any) => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https:///api.aimosa.io/AdsManager/Ads";
    const campaigns = [updateArray];
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ campaigns }),
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };
    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (response.ok) {
        navigate("/ads/ads-creation");
        toast("Created Successfully");
        resetData();
      } else {
        toast("Error On Creating");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("Error On Creating");
    }
  };
  const updateDataCampaign = async (updateArray: any) => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/AdsManager/Ads";
    const campaigns = updateArray;
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(campaigns),
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };
    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (response.ok) {
        navigate("/ads/ads-creation");
        toast("Updated Successfully");
        resetData();
      } else {
        toast("Error On Updated");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("Error On Creating");
    }
  };
  const selectedAllAdkeyWords = (e) => {
    setIsMatchList((prevState: any) => {
      const updatedList = prevState.map((listItem) => ({
        ...listItem,
        isChecked: !listItem.isChecked,
      }));
      return updatedList;
    });
  };
  const removematchtypeHandler = (id: any, e: any) => {
    setIsMatchList((prevState: any) => {
      const updatedList = prevState.map((item: any, index) =>
        item.id === id ? { ...item, name: "" } : item
      );
      return updatedList;
    });
  };

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Container fluid>
          <Row>
            <Col md={8}>
              <p className="set-dynamic pt-3">
                Set dynamic campaign names. You can check the alternative
                placeholders{" "}
                <Tooltip
                  title={
                    <div className="popup-custom-box">
                      <p>Alternative Placeholders:</p>
                      <ul>
                        <li>&#10100;ASIN&#10101;</li>
                        <li>&#10100;Layer&#10101;</li>
                        <li>&#10100;Match Type&#10101;</li>
                        <li>&#10100;Tag&#10101;</li>
                        <li>&#10100;Targeting Type&#10101;</li>
                      </ul>
                    </div>
                  }
                  placement="bottom"
                >
                  <span className="here-tooltip"> here</span>
                </Tooltip>
              </p>
              <div className="form-group">
                <h6 className="campaign-name-text">Campaign Name</h6>
                <div className="placeholder-input-wrapper">
                  <input
                    disabled={params.id ? true : false}
                    // disabled
                    className="custom-input-main"
                    name="createCampaignFormData.campaignName"
                    onChange={handleChange}
                    value={iscreateForm.campaignName}
                  />
                </div>
              </div>
              <h6 className="my-3 campaign-name-text">
                MatchType Options
                <img src={InfoIcon} alt="" />
              </h6>
              <div className="d-flex flex-wrap">
                <div
                  style={{ marginRight: "20px" }}
                  className="d-flex align-items-baseline"
                >
                  <label>
                    <input
                      type="radio"
                      className="form-check-input mx-1"
                      name="matchTypeOption"
                      value="1"
                      disabled={params.id ? true : false}
                      checked={isSelectedCampaign === 1}
                      onChange={(e) => handleChanges(1)}
                    />
                  </label>
                  <p className="set-dynamic">
                    Create each matchtype in the same campaign
                  </p>
                </div>
                <div className="d-flex align-items-baseline">
                  <label>
                    <input
                      type="radio"
                      className="form-check-input mx-1"
                      name="matchTypeOption"
                      value="0"
                      disabled={params.id ? true : false}
                      checked={isSelectedCampaign === 0}
                      onChange={(e) => handleChanges(0)}
                    />
                  </label>
                  <p className="set-dynamic">
                    {" "}
                    Create each matchtype in individual campaigns
                  </p>
                </div>
              </div>
              <h6 className="campaign-name-text">
                {" "}
                {isSelectedCampaign === 1
                  ? "Select 1 of these options below :"
                  : null}
              </h6>
              {isSelectedCampaign === 1 ? (
                <>
                  {isimgstate === 1 ? (
                    <div className="d-flex" style={{ gap: "20px" }}>
                      <div>
                        <img
                          src={require("../../../../../assets/images/auto1-1.jpg")}
                          alt="alt-same"
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            cursor: params.id ? "not-allowed" : "pointer",
                          }}
                          {...(!params.id
                            ? { onClick: () => isimgChange(1) }
                            : null)}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../../../../../assets/images/auto1.jpg")}
                          alt="alt-individual"
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            cursor: params.id ? "not-allowed" : "pointer",
                          }}
                          {...(!params.id
                            ? { onClick: () => isimgChange(2) }
                            : null)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="d-flex"
                      style={{
                        gap: "20px",
                        cursor: params.id ? "not-allowed" : "pointer",
                      }}
                    >
                      <div>
                        <img
                          src={require("../../../../../assets/images/auto1-2.png")}
                          alt="alt-same"
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            cursor: params.id ? "not-allowed" : "pointer",
                          }}
                          {...(!params.id
                            ? { onClick: () => isimgChange(1) }
                            : null)}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../../../../../assets/images/auto2.jpg")}
                          alt="alt-same"
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            cursor: params.id ? "not-allowed" : "pointer",

                            // cursor: this.state.CreateFormData.id
                            //   ? "not-allowed"
                            //   : "pointer",
                          }}
                          {...(!params.id
                            ? { onClick: () => isimgChange(2) }
                            : null)}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div>
                  <img
                    src={individualcampaign}
                    alt="alt-individualcampaign"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              {/* <div className="img-layout">
                       <div>
                         <div className="auto-img-border">
                           <div className="auto-border">Auto Campaign</div>
                           <div className="auto-border-line"></div>
                           <div className="auto-border">Ad Group</div>
                         </div>
                         <div style={{ position: "relative" }}>
                           <div className="horizontal-line"></div>
                           <div className="d-flex justify-content-between">
                             <div style={{ position: "relative" }}>
                               <div className="auto-border-item">Close</div>
                               <div className="auto-border-doubleline"></div>
                             </div>
                             <div style={{ position: "relative" }}>
                               <div className="auto-border-item">Loose</div>
                               <div className="auto-border-doubleline"></div>
                             </div>
                             <div style={{ position: "relative" }}>
                               <div className="auto-border-item">Substitute</div>
                               <div className="auto-border-doubleline"></div>
                             </div>
                             <div style={{ position: "relative" }}>
                               <div className="auto-border-item">
                                 Complements
                               </div>
                               <div className="auto-border-doubleline"></div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div> */}
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <hr
                style={{
                  border: "1px solid #E8EAED",
                  borderWidth: "3px 0",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <div>
                <p className="placement-icon">Placement</p>
              </div>
              <div
                className="d-flex justify-content-between flex-wrap"
                style={{ gap: "20px" }}
              >
                <div style={{ flexGrow: "1" }} className="mb-3">
                  <label htmlFor="basic-url" className="form-label">
                    <span className="placement-text">
                      Top of search(firstPage)
                    </span>
                  </label>
                  <div className="d-flex mt-1">
                    <input
                      type="number"
                      className="custom-input"
                      placeholder="0"
                      name="topOfSearch"
                      aria-describedby="basic-addon1"
                      value={iscreateForm.placement?.topOfSearch}
                      onChange={(e) => placementChange(e, "topOfSearch")}
                    />
                    <span className="input-span" id="basic-addon1">
                      %
                    </span>
                  </div>
                  {/* {this.state.isError.topOfSearch === null ||
                           this.state.isError.topOfSearch ? null : (
                             <span className="text-danger">*requried</span>
                           )} */}
                </div>
                <div style={{ flexGrow: "1" }}>
                  <label htmlFor="basic-url" className="form-label">
                    <span className="placement-text">Product detail Pages</span>
                  </label>
                  <div className="mt-1 d-flex">
                    <input
                      type="number"
                      className="custom-input"
                      aria-label="Username"
                      name="productDetailPages"
                      placeholder="0"
                      aria-describedby="basic-addon1"
                      value={iscreateForm.placement?.productDetailPages}
                      onChange={(e) => placementChange(e, "productDetailPages")}
                    />
                    <span className="input-span" id="basic-addon1">
                      %
                    </span>
                  </div>
                </div>
                <div style={{ flexGrow: "1" }}>
                  <label htmlFor="basic-url" className="form-label">
                    <span className="placement-text">Rest of Search</span>
                  </label>
                  <div className="mt-1 d-flex">
                    <input
                      type="number"
                      className="custom-input"
                      aria-label="Username"
                      placeholder="0"
                      aria-describedby="basic-addon1"
                      name="restOfSearch"
                      value={iscreateForm.placement?.restOfSearch}
                      onChange={(e) => placementChange(e, "restOfSearch")}
                    />
                    <span className="input-span" id="basic-addon1">
                      %
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <hr
                style={{
                  border: "1px solid #E8EAED",
                  borderWidth: "3px 0",
                }}
              />
            </Col>
          </Row>
          <Row>
            <div>
              <p className="marketplace-icon">Select Marketplaces</p>
            </div>
            {!isError.marketPlaces && (
              <span className="text-danger">*requried</span>
            )}
            <Col md={8}>
              <div className="marketplaces-id">{marketplaces}</div>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <hr
                style={{
                  border: "1px solid #E8EAED",
                  borderWidth: "3px 0",
                }}
              />
            </Col>
          </Row>
          <Row>
            <div>
              <p className="Setting-icon">Settings</p>
            </div>
            <Col md={8} lg={4}>
              <span className="placement-text">Campaign Bidding Strategy</span>
              <div className="setting-button flex-wrap flex-sm-nowrap">
                <div
                  className={`${isSelected === "Down only" ? "selected" : ""}`}
                  onClick={() => handleOptionClick("Down only")}
                >
                  Down only
                </div>
                <div
                  className={`${isSelected === "Up&Down" ? "selected" : ""}`}
                  onClick={() => handleOptionClick("Up&Down")}
                >
                  Up & Down
                </div>
                <div
                  className={`${isSelected === "Fixed" ? "selected" : ""}`}
                  onClick={() => handleOptionClick("Fixed")}
                >
                  Fixed
                </div>
              </div>
              {!isError.campaignBiddingStrategy && (
                <span className="text-danger">*requried</span>
              )}
              <div className="mt-3">
                <label htmlFor="basic-url" className="form-label">
                  <span className="placement-text">Portfolio</span>
                </label>
                <select
                  className="form-select"
                  name="portfolio"
                  aria-label="Default select example"
                  onChange={(e) => settingChange(e, "portfolio")}
                  value={iscreateForm.settings.portfolio}
                >
                  <option value="">Choose Portfolio</option>
                  {isPortfolio?.map((list: any, i) => (
                    <option value={list.portfolioId} key={i}>
                      {list.portfolioName}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col md={8} lg={4}>
              <label htmlFor="basic-url" className="form-label">
                <span className="placement-text">Daily Budget</span>
              </label>
              <div className="daily-budget">
                <span className="input-span-daily" id="basic-addon1">
                  $
                </span>
                <input
                  type="number"
                  name="dailyBudget"
                  className="custom-input-daily"
                  placeholder="0"
                  aria-describedby="basic-addon1"
                  value={iscreateForm.settings.dailyBudget}
                  onChange={(e) => settingChange(e, "dailyBudget")}
                />
              </div>

              {!isError.dailyBudget && (
                <span className="text-danger">*requried</span>
              )}
              <div className="Daily-Budget-dates mt-3">
                <div>
                  <label htmlFor="basic-url" className="form-label">
                    <span className="placement-text">Start</span>
                  </label>
                  <br />

                  <DatePicker
                    disabled={params.id ? true : false}
                    className="date-picker-autocampign"
                    selected={isDate.startDate}
                    // value={iscreateForm.settings[0]?.startDate}
                    minDate={new Date()}
                    onChange={(e) => {
                      handleChangeDate("start", e);
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="basic-url" className="form-label">
                    <span className="placement-text">End</span>
                  </label>
                  <br />
                  <DatePicker
                    minDate={new Date()}
                    className="date-picker-autocampign"
                    selected={isDate.endDate}
                    // value={this.state.CreateFormData.settings.endDate}
                    onChange={(e) => {
                      handleChangeDate("end", e);
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md={8}>
              <hr
                style={{
                  border: "1px solid #E8EAED",
                  borderWidth: "3px 0",
                }}
              />
            </Col>
          </Row> */}
          <Row>
            <Col md={8}>
              <hr
                style={{
                  border: "1px solid #E8EAED",
                  borderWidth: "3px 0",
                }}
              />
            </Col>
          </Row>
          <Row>
            <div>
              <p className="Targeting-icon">Negative Targeting</p>
            </div>
            <Col md={8}>
              <Tabs
                defaultActiveKey="NegativeKeywords"
                onSelect={handleTabSelect}
              >
                <Tab eventKey="NegativeKeywords" title="Negative Keywords">
                  <Container>
                    <Row>
                      <Col md={12}>
                        <div className="my-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              placeholder="Enter Keywords"
                              name="negativeKeyword"
                              id="inlineRadio3"
                              checked={isAdKeywordType === "Negativeexact"}
                              value="Negativeexact"
                              onChange={(e) => addKeyWordType(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio3"
                            >
                              Negative exact
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="negativeKeyword"
                              id="inlineRadio4"
                              value="Negativephrase"
                              checked={isAdKeywordType === "Negativephrase"}
                              onChange={(e) => addKeyWordType(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio4"
                            >
                              Negative phrase
                            </label>
                          </div>
                          {/* <div className="form-check form-check-inline">
                                   <input
                                     className="form-check-input"
                                     type="checkbox"
                                     name="negativeKeyword"
                                     id="inlineRadio4"
                                     value="Negative phrase"
                                     onChange={(e) => this.addKeyWordType(e)}
                                   />
                                   <label
                                     className="form-check-label"
                                     htmlFor="inlineRadio4"
                                   >
                                     Exact
                                   </label>
                                 </div> */}
                        </div>
                        <div className="add-keyword-container">
                          <textarea
                            name=""
                            placeholder="Enter Keywords"
                            id="addKeywordsText"
                            className="form-control mb-3 input-box-control-auto"
                            onChange={(e) =>
                              setIsAddTempAdKeywords(e.target.value)
                            }
                            style={{ width: "100%" }}
                            value={isAddTempAdKeywords}
                          ></textarea>
                          <button
                            type="button"
                            className="btn btn-outline mb-2 add-keyword-btn"
                            onClick={(e) => adkeyWordSubmit()}
                            // disabled={!this.state.adKeywordType}
                            style={{
                              background: "#00FF00",
                            }}
                          >
                            Add Keyword
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          className="create-campaing-form table-responsive border"
                          style={{ width: "100%" }}
                        >
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Keywords</th>
                                <th>Match Type</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {isAdKeywords.map((adKeyword: any, index) =>
                                !adKeyword.isDeleted ? (
                                  <tr key={index}>
                                    <td>{adKeyword?.keyword}</td>
                                    <td>{adKeyword.type}</td>
                                    <td className="text-center more-action">
                                      <i
                                        onClick={() => removeFormFields(index)}
                                      >
                                        <img src={DeleteIcon} alt="" />
                                      </i>
                                    </td>
                                  </tr>
                                ) : null
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={12}
                        className="d-flex justify-content-between my-4 0 keyword-added"
                      >
                        <p>
                          {
                            isAdKeywords?.filter(
                              (item, index) => !item.isDeleted
                            )?.length
                          }{" "}
                          keywords added
                        </p>
                        <span
                          className="text-end remove-all-link"
                          onClick={removeAllAdkeyWords}
                        >
                          Remove All
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </Tab>
                <Tab eventKey="NegativeProducts" title="Negative Products">
                  <Container>
                    <Row>
                      <Col md={12}>
                        <div className="mt-2">
                          <h6 className="target-types">Targeting Types</h6>
                        </div>
                        {/* <div className="my-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="negativeKeyword"
                              id="inlineRadio4"
                              value="Negative phrase"
                              // onChange={(e) => this.addKeyWordType(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio4"
                            >
                              Product
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="negativeKeyword"
                              id="inlineRadio4"
                              value="Negative phrase"
                              // onChange={(e) => this.addKeyWordType(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio4"
                            >
                              Category
                            </label>
                          </div>
                        </div> */}
                        <div className="add-keyword-container">
                          <textarea
                            name=""
                            id="addKeywordsText"
                            placeholder="Enter ASINs"
                            className="form-control mb-3 input-box-control-auto"
                            onChange={(e) =>
                              setIsAddTempAdKeywords(e.target.value)
                            }
                            style={{ width: "100%" }}
                            value={isAddTempAdKeywords}
                          ></textarea>
                          <button
                            type="button"
                            className="btn btn-outline mb-2 add-keyword-btn"
                            onClick={(e) => adkeyWordSubmit()}
                            // disabled={!this.state.adKeywordType}
                            style={{
                              background: "#00FF00",
                            }}
                          >
                            Add ASIN
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          className="create-campaing-form table-responsive border"
                          style={{ width: "100%" }}
                        >
                          <table className="table">
                            <thead>
                              <tr>
                                <th>ASINs</th>
                                <th>Match Type</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {isAdKeywords.map((adKeyword: any, index) =>
                                !adKeyword.isDeleted ? (
                                  <tr key={index}>
                                    <td>{adKeyword?.keyword}</td>
                                    <td>{adKeyword.type}</td>
                                    <td className="text-center more-action">
                                      <i
                                        onClick={() => removeFormFields(index)}
                                      >
                                        <img src={DeleteIcon} alt="" />
                                      </i>
                                    </td>
                                  </tr>
                                ) : null
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={12}
                        className="d-flex justify-content-between my-4 0 keyword-added"
                      >
                        <p>
                          {
                            isAdKeywords?.filter(
                              (item, index) => !item.isDeleted
                            )?.length
                          }{" "}
                          ASINs added
                        </p>
                        <span
                          className="text-end remove-all-link"
                          onClick={removeAllAdkeyWords}
                        >
                          Remove All
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </Tab>
              </Tabs>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <hr
                style={{
                  border: "1px solid #E8EAED",
                  borderWidth: "3px 0",
                }}
              />
            </Col>
          </Row>
          {/* <Row>
                   <div>
                     <h6>Add Group(s)</h6>
                   </div>
                   <Col md={8}>
                     <form>
                       <input
                         type="text"
                         className="form-control"
                         defaultValue={""}
                         aria-label="Username"
                         placeholder="enter ad group name"
                         aria-describedby="basic-addon1"
                       />
                     </form>
                   </Col>
                 </Row>
                 <Row>
                   <Col className="my-3">
                     <div className="add-gropu-btn">Ad Group</div>
                   </Col>
                 </Row> */}
          <Row>
            <Col md={8}>
              <div
                className="d-flex justify-content-between flex-wrap"
                style={{ columnGap: "10px" }}
              >
                <div style={{ flexGrow: "1" }}>
                  <div>
                    <h6 className="custom-input-text">Targeting Bid (X)</h6>
                  </div>
                  <div>
                    <input
                      type="number"
                      name="targetingBid"
                      className="form-control"
                      placeholder="0"
                      aria-describedby="basic-addon1"
                      onChange={(e) => keywordChange(e, "targetingBid")}
                      value={iscreateForm.dynamicBidding.targetingBid}
                    />
                  </div>
                  {/* {this.state.isError.targetingBid === null ||
                             this.state.isError.targetingBid ? null : (
                               <span className="text-danger">*requried</span>
                             )} */}
                  {!isError.targetingBid && (
                    <span className="text-danger">*requried</span>
                  )}
                </div>

                <div style={{ flexGrow: "1" }}>
                  <div>
                    <h6 className="custom-input-text">Default Bid</h6>
                  </div>
                  <div>
                    <input
                      name="defaultBid"
                      type="number"
                      value={iscreateForm.dynamicBidding.defaultBid}
                      className="form-control"
                      placeholder="0"
                      aria-describedby="basic-addon1"
                      onChange={(e) => keywordChange(e, "defaultBid")}
                    />
                  </div>
                  {/* {this.state.isError.defaultBid === null ||
                             this.state.isError.defaultBid ? null : (
                               <span className="text-danger">*requried</span>
                             )} */}
                  {!isError.defaultBid && (
                    <span className="text-danger">*requried</span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Col md={8}>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="custom-input-text">Match Types</h6>
                <span
                  className="text-end remove-all-link"
                  id="selectAll"
                  // checked={this.state.matchTypesList.every(
                  //   (item: any) => item.isChecked
                  // )}
                  onClick={(e) => {
                    selectedAllAdkeyWords(e);
                  }}
                >
                  {isMatchList.some((item: any) => !item.isChecked)
                    ? "Select All"
                    : "Deselect All"}
                </span>
              </div>
            </Col>
            <Col md={8} className="my-3">
              <div
                className="justify-content-between d-flex flex-wrap flex-sm-nowrap"
                style={{ gap: "10px" }}
              >
                {isMatchList.map((item: any, index) => {
                  return (
                    <div style={{ flexGrow: "1" }}>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`checkbox-${item.id}`}
                          value="Negative exact"
                          checked={item.isChecked}
                          onChange={(e) => {
                            keywordChangeHandler(e, item.id);
                          }}
                        />
                        <label
                          className="form-check-label custom-input-text"
                          htmlFor="inlineRadio3"
                        >
                          {item.title}
                        </label>
                      </div>
                      <div className="d-flex">
                        <input
                          className="custom-input"
                          type="number"
                          value={item.name}
                          name={item.nameid}
                          placeholder="0"
                          aria-describedby="basic-addon1"
                          onChange={keywordChangedynamic}
                        />
                        <span
                          className="input-span"
                          id="basic-addon1"
                          onClick={(e) => removematchtypeHandler(item.id, e)}
                        >
                          X
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
        <div className="form-button-group">
          <Link to={"/ads/ads-creation"} className="btn btn-outline-primary">
            Back
          </Link>

          <button className="btn btn-primary" type="submit">
            {params.keyword ? "Next" : "Submit"}
          </button>
        </div>
      </Form>
    </>
  );
};

export default AutoCampaign;
// import React, { useEffect } from "react";
// import "../../index.css";
// import dot1 from "../../../../../assets/map01.jpg";
// import dot2 from "../../../../../assets/map02.jpg";
// import dot3 from "../../../../../assets/map03.jpg";
// interface ImageGalleryProps {
//   // Define any props here if needed
// }

// const ImageGallery: React.FC<ImageGalleryProps> = () => {
//   const imageUrls: string[] = [dot1, dot3, dot2];

//   const handleScroll = () => {
//     // Your vertical scroll handling logic here
//     // You can update the visible image based on the scroll position
//   };

//   // Attach the scroll event listener when the component mounts
//   useEffect(() => {
//     const imageContainer = document.getElementById(
//       "imageContainer"
//     ) as HTMLElement;
//     if (imageContainer) {
//       imageContainer.addEventListener("scroll", handleScroll);

//       // Remove the event listener when the component unmounts
//       return () => {
//         imageContainer.removeEventListener("scroll", handleScroll);
//       };
//     }
//   }, []);

//   return (
//     <div id="imageContainer" className="image-container">
//       {imageUrls.map((imageUrl, index) => (
//         <img key={index} src={imageUrl} alt={`Image ${index + 1}`} />
//       ))}
//     </div>
//   );
// };

// export default ImageGallery;
