/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import LogoIcon from "../../../../../assets/brand/logo-icon.svg";
import SwitchIcon from "../../../../../assets/images/icons/switch-icon.svg";
import DuplicateIcon from "../../../../../assets/images/icons/duplicate-icon.svg";
import RemoveIcon from "../../../../../assets/images/icons/trash-icon.svg";
import moveIcon from "../../../../../assets/images/icons/sort-move-icon.svg";

function AddRulesUntil(props) {
  const [ruleDataLoading, setRuleDataLoading] = useState(true);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [metaData, setMetaData] = useState([]);
  const [isUnable, setIsUnable] = useState(null);
  const [rulesMetaData, setRulesMetaData] = useState({});
  const [actionCriteriaDetails, setActionCriteriaDetails] = useState([]);
  const [newActionCriteriaDetails, setNewActionCriteriaDetails] = useState([]);
  const [showAdditionalInput, setShowAdditionalInput] = useState(false);
  const [isSwitch, setIsSwitch] = useState(false);

  const [formValues, setFormValues] = useState([
    {
      conditionFilter: "",
      conditionName: "",
      unitName: "",
      conditionValue: "",
      switch: "List",
      conditionNameOperand: "",
    },
  ]);

  useEffect(() => {
    if (props.rulesMetaData) {
      let actionCriteriaDetails = props.rulesMetaData.actionUpToCriteriaDetails;
      if (actionCriteriaDetails !== undefined) {
        setRulesMetaData(props.rulesMetaData);
        setActionCriteriaDetails(props.rulesMetaData.actionCriteriaDetails);
        setRuleDataLoading(false);
      }
    }
    setMetaData(props.metaData);
  }, [props.rulesMetaData, rulesMetaData, props.metaData, metaData]);

  useEffect(() => {
    if (props.metaData && props.rulesMetaData.actionUpToCriteriaDetails) {
      if (props.metaData.text) {
        buildConditionDropdownData(
          props.rulesMetaData.actionUpToCriteriaDetails,
          props.metaData
        );
        setMetaDataLoading(false);
      }
    }
  }, [props.rulesMetaData, props.metaData]);

  useEffect(() => {
    if (props.wizardData.untilWizard.length > 0) {
      let actionUpToCriteriaList = [];
      let actionUpToCriteriaData = props.wizardData.untilWizard;
      for (let i = 0; i < actionUpToCriteriaData.length; i++) {
        if (actionUpToCriteriaData[i].expressions.operand) {
          let obj = {
            conditionFilter: actionUpToCriteriaData[i].expressions.operand,
            conditionName: actionUpToCriteriaData[i].expressions.operator,
            unitName: actionUpToCriteriaData[i].expressions.unit,
            conditionValue:
              actionUpToCriteriaData[i].expressions.value[0].inputExpression
                .input,
            switch: actionUpToCriteriaData[i].expressions.value[0].inputType,
            conditionNameOperand:
              actionUpToCriteriaData[i].expressions.value[0].inputExpression
                .operand,
          };
          actionUpToCriteriaList.push(obj);
        }
      }
      setFormValues(actionUpToCriteriaList);
      if (actionUpToCriteriaList.length < 1) {
        setFormValues([
          ...formValues,
          {
            conditionFilter: "",
            conditionName: "",
            unitName: "",
            conditionValue: "",
            switch: "List",
            conditionNameOperand: "",
          },
        ]);
      }
    } else {
      if (formValues.length < 1) {
        setFormValues([
          ...formValues,
          {
            conditionFilter: "",
            conditionName: "",
            unitName: "",
            conditionValue: "",
            switch: "List",
            conditionNameOperand: "",
          },
        ]);
      }
    }
  }, []);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];

    if (e.target.name === "conditionValue") {
      // Update the specific field for "From" value
      newFormValues[i]["conditionValue"] = e.target.value;
    } else if (e.target.name === "additionalValue") {
      // Update the specific field for "To" value
      newFormValues[i]["additionalValue"] = e.target.value;
    } else {
      // Update other fields as usual
      newFormValues[i][e.target.name] = e.target.value;

      // Update showAdditionalInput based on conditionName and unitName
      setShowAdditionalInput(
        (e.target.name === "conditionName" && e.target.value === "Between") ||
          (e.target.name === "unitName" &&
            newFormValues[i]["conditionName"] === "Between")
      );
    }

    // Create the value array with both "From" and "To" values
    let fromValue = {
      inputType: "List",
      inputExpression: {
        operand: "",
        input: newFormValues[i]["conditionValue"],
      },
    };

    let toValue = {
      inputType: "List",
      inputExpression: {
        operand: "",
        input: newFormValues[i]["additionalValue"],
      },
    };

    // Create the formData with "ifData" type
    let formData = {
      type: "ifData",
      ifData: [fromValue, toValue],
      unit: newFormValues[i]["unitName"],
    };

    // Callbacks and updates
    props.parentCallback(formData);
    props.wizardDataCallback(formData);
    wizardDataUpdate(newFormValues);
    console.log(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        targetName: "",
        conditionFilter: "",
        conditionName: "",
        conditionValue: "",
        switch: "List",
        conditionNameOperand: "",
      },
    ]);
  };
  let duplicateFormFields = (i) => {
    let newFormValues = [...formValues];
    let duplicateRow = {
      targetName: newFormValues[i].targetName,
      conditionFilter: newFormValues[i].conditionFilter,
      conditionName: newFormValues[i].conditionName,
      conditionValue: newFormValues[i].conditionValue,
      switch: newFormValues[i].switch,
      conditionNameOperand: newFormValues[i].conditionNameOperand,
    };
    newFormValues.push(duplicateRow);
    setFormValues(newFormValues);
    wizardDataUpdate();
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    wizardDataUpdate(newFormValues); // Pass the updated form values to wizardDataUpdate
  };
  let switchFormFields = (i) => {
    setIsSwitch(!isSwitch);
    let newFormValues = [...formValues];
    if (newFormValues[i]["switch"] === "List") {
      newFormValues[i]["switch"] = "Calculated";
    } else {
      newFormValues[i]["switch"] = "List";
    }
    setFormValues(newFormValues);
    wizardDataUpdate();
  };

  let handleSubmit = (event) => {};

  function wizardDataUpdate(updatedFormValues) {
    // Ensure that updatedFormValues is defined and is an array with a length property
    if (!Array.isArray(updatedFormValues) || !updatedFormValues.length) {
      console.error("Invalid form values:", updatedFormValues);
      return;
    }

    let actionUpToCriteria = [];
    for (let i = 0; i < updatedFormValues.length; i++) {
      // Check if the form value exists and is not null or undefined
      if (updatedFormValues[i]) {
        let obj = {
          expressions: {
            operand: updatedFormValues[i].conditionFilter,
            operator: updatedFormValues[i].conditionName,
            unit: updatedFormValues[i].unitName,
          },
        };

        // Handle "Between" condition
        if (updatedFormValues[i].conditionName === "Between") {
          obj.expressions.value = [
            {
              inputType: updatedFormValues[i].switch,
              inputExpression: {
                operand: updatedFormValues[i].conditionNameOperand,
                input: updatedFormValues[i].conditionValue,
              },
            },
            {
              inputType: updatedFormValues[i].switch,
              inputExpression: {
                operand: updatedFormValues[i].conditionNameOperand,
                input: updatedFormValues[i].additionalValue, // additionalValue for the "To" value
              },
            },
          ];
        } else {
          // Handle other conditions
          obj.expressions.value = [
            {
              inputType: updatedFormValues[i].switch,
              inputExpression: {
                operand: updatedFormValues[i].conditionNameOperand,
                input: updatedFormValues[i].conditionValue,
              },
            },
          ];
        }

        actionUpToCriteria.push(obj);
      } else {
        console.error(
          "Invalid form value at index",
          i,
          ":",
          updatedFormValues[i]
        );
      }
    }

    let formData = {
      type: "actionUpToCriteria",
      actionUpToCriteria: actionUpToCriteria,
    };

    props.parentCallback(formData);
    props.wizardDataCallback(formData);
  }

  function buildConditionDropdownData(listData, metaDataList) {
    let result = metaDataList;
    if (listData && result) {
      for (let i = 0; i < listData.length; i++) {
        listData[i]["id"] = i + 1;
        listData[i]["selected"] = false;
        let slectedConditionType =
          listData[i]["filterConditionType"].toLowerCase();
        let conditionTypeList = result[slectedConditionType];
        listData[i]["conditionTypes"] = conditionTypeList;
      }
      setNewActionCriteriaDetails(listData);
    }
  }
  const SubSelectOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.conditionFilter) {
      let findIndex = newActionCriteriaDetails.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      if (findIndex > -1) {
        let conditionData = newActionCriteriaDetails[findIndex];
        let optionData = conditionData.conditionTypes;
        if (optionData) {
          if (optionData.length > 0) {
            return optionData.map((options, i) => (
              <option key={i} value={options.value}>
                {options.name}
              </option>
            ));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const UnitSelectOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.conditionFilter) {
      let findIndex = newActionCriteriaDetails.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      if (findIndex > -1) {
        let conditionData = newActionCriteriaDetails[findIndex];
        let optionData = conditionData.units;
        if (optionData) {
          if (optionData.length > 0) {
            return optionData.map((options, i) => (
              <option key={i} value={options}>
                {options}
              </option>
            ));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const ConditionNameOperandSelectOptions = (props) => {
    let { index, eleData, newActionCriteriaDetails, handleChange } = props;
    if (eleData.conditionNameOperand) {
      let findIndex = newActionCriteriaDetails.findIndex(
        (obj) => obj.name === eleData.conditionNameOperand
      );
      if (findIndex > -1) {
        let conditionData = newActionCriteriaDetails[findIndex];
        let optionData = conditionData.units;
        if (optionData && optionData.length > 0) {
          return optionData.map((options, i) => (
            <option key={i} value={options}>
              {options}
            </option>
          ));
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="wizard-step ifpage-css">
        <h5>Until</h5>
        {!metaDataLoading && !ruleDataLoading ? (
          <>
            {formValues.map((element, index) => (
              <>
                {index !== 0 && (
                  <div className="rule-and-or-container">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDisabled"
                        id="flexRadioDisabled"
                        checked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDisabled"
                      >
                        And
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDisabled"
                        id="flexRadioCheckedDisabled"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioCheckedDisabled"
                      >
                        Or
                      </label>
                    </div>
                  </div>
                )}
                <div className="card">
                  <Row>
                    <Col md={12}>
                      <div>
                        <form style={{ justifyContent: "space-between" }}>
                          <div className="form-row">
                            <div className="ele-move-box">
                              <img src={moveIcon} alt="" />
                            </div>
                            <div className="form-field-group ">
                              <div
                                className="until-form-select width-100p"
                                style={{ margin: "0 10px" }}
                              >
                                {" "}
                                <select
                                  style={{
                                    // width: "307px",
                                    height: "40px",
                                    top: "24px",
                                    left: "60px",
                                  }}
                                  className="form-select  focus-border-green"
                                  name="conditionFilter"
                                  value={element.conditionFilter || ""}
                                  onChange={(e) => {
                                    handleChange(index, e);
                                  }}
                                >
                                  <option value="">--Select--</option>
                                  {newActionCriteriaDetails.map((list, i) => (
                                    <option value={list.name}>
                                      {list.displayName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div
                                className="until-unit-form width-100p"
                                style={{ margin: "0 10px" }}
                              >
                                <select
                                  style={{
                                
                                    height: "40px",
                                    top: "24px",
                                    left: "383px",
                                    boxShadow:"none",
                                    borderColor: "none ",
                                  }}
                                  className="form-select  focus-border-green"
                                  name="conditionName"
                                  value={element.conditionName || ""}
                                  onChange={(e) => handleChange(index, e)}
                                >
                                  <option value="">--Select--</option>
                                  <SubSelectOptions
                                    index={index}
                                    eleData={element}
                                  />
                                </select>
                              </div>
                              {isUnable === "Clicks" ||
                              isUnable === "Impressions" ||
                              isUnable === "Orders" ||
                              isUnable === "Page Read [Ad]" ? null : (
                                <div
                                  className="until-unitsselect-form width-100p"
                                  style={{ margin: "0 10px" }}
                                >
                                  <select
                                    style={{
                                   
                                      height: "40px",
                                      top: "24px",
                                      left: "519px",
                                    
                                    }}
                                    className="form-select  focus-border-green"
                                    name="unitName"
                                    value={element.unitName || ""}
                                    onChange={(e) => handleChange(index, e)}
                                  >
                                    <option value="">--Select--</option>
                                    {!isSwitch ? (
                                      <UnitSelectOptions
                                        index={index}
                                        eleData={element}
                                      />
                                    ) : (
                                      <ConditionNameOperandSelectOptions
                                        index={index}
                                        eleData={element}
                                        newActionCriteriaDetails={
                                          newActionCriteriaDetails
                                        }
                                        handleChange={handleChange}
                                      />
                                    )}
                                  </select>
                                </div>
                              )}
                              {element.switch === "Calculated" ? (
                                <>
                                  <div className="form-field width-500 rule-switch-container">
                                    <div
                                      className="until-metric width-100p"
                                      style={{ margin: "0 10px" }}
                                    >
                                      <select
                                        style={{
                                          width: "250px",
                                          height: "40px",
                                          top: "24px",
                                          left: "519px",
                                        }}
                                        className="form-select  focus-border-green"
                                        name="conditionNameOperand"
                                        value={
                                          element.conditionNameOperand || ""
                                        }
                                        onChange={(e) => handleChange(index, e)}
                                      >
                                        <option value="">--Select--</option>

                                        {newActionCriteriaDetails.map(
                                          (list, i) => (
                                            <option key={i} value={list.name}>
                                              {list.displayName}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    <div className="multiply-sympol-box">x</div>
                                    <div className="until-dollar width-100p">
                                      <input
                                        style={{
                                          width: "195px",
                                          height: "40px",
                                          top: "24px",
                                          left: "905px",
                                        }}
                                        type="text"
                                        placeholder="enter Value"
                                        name="conditionValue"
                                        className="form-control"
                                        value={element.conditionValue || ""}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="untill-values width-100p">
                                  <input
                                    style={{
                                      // width: "445px",
                                      height: "40px",
                                      top: "24px",
                                      left: "655px",
                                    }}
                                    type="text"
                                    placeholder="enter value"
                                    name="conditionValue"
                                    className="form-control"
                                    value={element.conditionValue || ""}
                                    onChange={(e) => handleChange(index, e)}
                                  />
                                </div>
                              )}
                              {showAdditionalInput && (
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      margin: "0 10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    To
                                  </div>
                                  <div className="form-field width-100p">
                                    <input
                                      type="text"
                                      placeholder="enter To Value"
                                      name="additionalValue"
                                      className="form-control"
                                      value={element.additionalValue || ""}
                                      onChange={(e) => handleChange(index, e)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="form-action a-3">
                              <div
                                className="icon-box-white cursor"
                                onClick={() => switchFormFields(index)}
                              >
                                <img src={SwitchIcon} alt="" />
                              </div>
                              <div
                                className="icon-box-white cursor"
                                onClick={() => duplicateFormFields(index)}
                              >
                                <img src={DuplicateIcon} alt="" />
                              </div>
                              {index !== 0 && (
                                <div
                                  className="icon-box-gray cursor"
                                  onClick={() => removeFormFields(index)}
                                >
                                  <img src={RemoveIcon} alt="" />
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            ))}
          </>
        ) : (
          <div className="loading-container">
            <div className="loading-text">
              <span className="logicon">
                <img src={LogoIcon} alt="logo-small"></img>
              </span>
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
            </div>
          </div>
        )}

        <div>
          <button
            type="button"
            className="btn btn-link add-more-btn"
            onClick={() => addFormFields()}
          >
            <i className="circle-add-icon"></i> Add Row
          </button>
        </div>
      </div>
    </>
  );
}

export default AddRulesUntil;
