import React, { Component, useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import InfoButton from '../Common/InfoButton';
import MoreActionIcon from '../../assets/images/icons/more-action-icon.svg';
import DashboardService from '../../services/DashboardService';
import MasterDataService from '../../services/MasterDataService';
import DonutPie from '../Charts/DonutPie';
import { bottom } from '@popperjs/core';
import DynamicWidthDropdown from '../Common/DynamicWidthDropdown/DynamicWidthDropdown';
import NumberFormatter from '../Common/NumberFormatter';

interface DonutProps {
    defaultCategory: string;
    defaultMetric: string;
    canSwitch: bool;
    filters: any; // Replace with the actual type for your filters
}


const Donut: React.FC<DonutProps> = ({ defaultCategory, defaultMetric, canSwitch, filters }) => {
    const [loading, setLoading] = useState(true);
    const [chartSeries, setChartSeries] = useState([]);
    const [chartLabels, setChartLabels] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    function getListItemByKey(listItem, name) {
        // Check if listItem is an array
        if (Array.isArray(listItem)) {
            // Use find only if listItem is an array
            return listItem.find(item => item.name === name) ?? null;
        } else {
            // Return null if listItem is not an array
            return null;
        }
    }

    const [selectedCategory, setSelectedCategory] = useState(defaultCategory || 'Marketplace');
    const [selectedMetric, setSelectedMetric] = useState(defaultMetric);
    const [selectedCategoryData, setSelectedCategoryData] = useState({});
    const [selectedMetricData, setSelectedMetricData] = useState({
        name: '',
        unit: '',
        description: '',
        value: 0.0,
        average: 0.0,
        sum: 0.0,
        min: 0.0,
        max: 0.0,
        growthRate: 0.0,
        data: []
    });

    const seriesColors = [
        "#00976D",
        "#CAFCD0",
        '#00D26E',
        '#374151',
        '#008FFB', // Blue
        '#00E396', // Green
        '#FEB019', // Yellow
        '#FF4560', // Red
        '#775DD0', // Purple
        '#546E7A', // Grey
        '#26a69a', // Teal
        '#D10CE8', // Pink
        '#6610F2', // Indigo
        '#FFD700', // Gold
    ];

    function setDropDowns(catList) {
        setCategoryList(catList);
        let categoryData = getListItemByKey(catList, selectedCategory);
        if (!categoryData) {
            categoryData = catList[0];
            setSelectedCategory(categoryData.name);
        }
        setSelectedCategoryData(categoryData);
        let metricData = getListItemByKey(categoryData.childValues, selectedMetric);
        if (!metricData) {
            metricData = categoryData.childValues[0];
            setSelectedMetric(metricData.name);
        }
        setSelectedMetricData(metricData);
    }


    useEffect(() => {
        fetchMetaData();
    }, []);

    const fetchMetaData = async () => {
        setLoading(true);
        try {
            const metaData = await MasterDataService.getMetaData();
            if (metaData.DonutChart) {
                setDropDowns(metaData.DonutChart);
            }
        } catch (error) {
            console.error('Error fetching metrics list:', error);
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [selectedCategory, selectedMetric, categoryList, filters]);
    const fetchData = async () => {
        setLoading(true);
        try {

            var request = {
                globalFilters: filters,
                category: selectedCategory,
                metrics: selectedMetric
            };
            const donutChartData = await DashboardService.getDonutChartData(request);

            if (donutChartData && donutChartData.categoryValue && donutChartData.categoryValue.length > 0) {
                const series = donutChartData.categoryValue.map((item) => parseFloat(Number(item).toFixed(2)));
                const labels = donutChartData.categoryType;
                setChartSeries(series);
                setChartLabels(labels);
            }

        } catch (error) {
            console.error('Error fetching metric data:', error);
        }
        finally {
            setLoading(false);
        }
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setDropDowns(categoryList);
    };

    const handleMetricChange = (event) => {
        setSelectedMetric(event.target.value);
        let metricData = getListItemByKey(selectedCategoryData.childValues, selectedMetric);
        if (!metricData) {
            metricData = selectedCategoryData.childValues[0];
            setSelectedMetric(metricData.name);
        }
        setSelectedMetricData(metricData);
    };

    return (
        <div className="widget-container height-350px">
            <div className="widget-header">
                <Row>
                    <Col md={7} className="widget-select-container dot-con">
                        <div className="input-group filter d-flex align-items-center">
                            <div
                                style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    display: 'inline-flex'
                                }}
                            >
                                {(!canSwitch) && (
                                    <>
                                        {selectedCategoryData && (
                                            <>
                                                <span className="mx-2" style={{
                                                    color: '#111827',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '600',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word'
                                                }}>{selectedCategoryData.title}</span>
                                                <span style={{
                                                    color: '#9CA3AF',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '500',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word'
                                                }}>{selectedCategoryData.subTitle && (<span>[{selectedCategoryData.subTitle}]</span>)}</span>
                                                <InfoButton infoText={selectedCategoryData.description} />
                                            </>
                                        )}
                                    </>
                                )}
                                {canSwitch && categoryList && categoryList.length > 0 && (
                                    <DynamicWidthDropdown
                                        options={categoryList}
                                        selectedItem={selectedCategory}
                                        handleSelectChange={handleCategoryChange}
                                    ></DynamicWidthDropdown>
                                )}

                                <span className="mx-3">By</span>

                                {(!canSwitch) && (
                                    <>
                                        {selectedMetricData && (
                                            <>
                                                <span className="mx-2" style={{
                                                    color: '#111827',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '600',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word'
                                                }}>{selectedMetricData.title}</span>
                                                <span style={{
                                                    color: '#9CA3AF',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '500',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word'
                                                }}>{selectedMetricData.subTitle && (<span>[{selectedMetricData.subTitle}]</span>)}</span>
                                                <InfoButton infoText={selectedMetricData.description} />
                                            </>
                                        )}
                                    </>
                                )}
                                {canSwitch && selectedCategoryData.childValues && selectedCategoryData.childValues.length > 0 && (
                                    <DynamicWidthDropdown
                                        options={selectedCategoryData.childValues}
                                        selectedItem={selectedMetric}
                                        handleSelectChange={handleMetricChange}
                                    ></DynamicWidthDropdown>
                                )}

                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <Row>
                            <Col md={10}></Col>
                            <Col md={2}>
                                <div className="more-action-icon">
                                    <img src={MoreActionIcon} alt="info circle icon" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="app">
                <div className="row">
                    <div className="mixed-chart">
                        {
                            loading ?
                                (
                                    <div
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            height: "100%",
                                            marginTop: "140px",
                                        }}
                                        className="horizontalBarChart"
                                    >
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        <br></br>
                                        <div className="loading ms-4">Loading...</div>
                                    </div>
                                )
                                :
                                (chartSeries && chartSeries.length > 0) ?
                                    (
                                        <DonutPie series={chartSeries} colors={seriesColors} labels={chartLabels} />
                                    )
                                    :
                                    (
                                        < div style={{
                                            width: "100%",
                                            textAlign: "center",
                                            height: "100%",
                                            marginTop: "140px",
                                        }}
                                        >
                                            No Data
                                        </div>
                                    )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Donut;
