/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";

import LogoIcon from "../../../../../assets/brand/logo-icon.svg";
import SwitchIcon from "../../../../../assets/images/icons/switch-icon.svg";
import DuplicateIcon from "../../../../../assets/images/icons/duplicate-icon.svg";
import RemoveIcon from "../../../../../assets/images/icons/trash-icon.svg";
import moveIcon from "../../../../../assets/images/icons/sort-move-icon.svg";
import DefaultLayout from "../../../../../layouts/DefaultLayout";
function AddRulesIf(props) {
  const [ruleDataLoading, setRuleDataLoading] = useState(true);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [metaData, setMetaData] = useState([]);
  const [rulesMetaData, setRulesMetaData] = useState({});
  const [targetCriteriaDetails, setTargetCriteriaDetails] = useState([]);
  const [filterCriteriaDetails, setFilterCriteriaDetails] = useState([]);
  const [showAdditionalInput, setShowAdditionalInput] = useState(false);
  const [isSwitch, setIsSwitch] = useState(false);
  const [isopen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState([
    {
      targetName: "",
      conditionFilter: "",
      conditionName: "",
      unitName: "",
      conditionValue: "",
      switch: "List",
      conditionNameOperand: "",
    },
  ]);
  console.log(formValues);
  useEffect(() => {
    if (props.rulesMetaData) {
      let targetCriteriaDetails = props.rulesMetaData.targetCriteriaDetails;
      if (targetCriteriaDetails !== undefined) {
        setRulesMetaData(props.rulesMetaData);
        setTargetCriteriaDetails(
          props.rulesMetaData.targetCriteriaDetails.targets
        );
        setFilterCriteriaDetails(
          props.rulesMetaData.targetCriteriaDetails.filters
        );
        setRuleDataLoading(false);
      }
    }
    setMetaData(props.metaData);
  }, [props.rulesMetaData, rulesMetaData, props.metaData, metaData]);

  useEffect(() => {
    if (props.metaData && props.rulesMetaData.targetCriteriaDetails) {
      if (props.metaData.text) {
        buildConditionDropdownData(
          props.rulesMetaData.targetCriteriaDetails.filters,
          props.metaData
        );
        setMetaDataLoading(false);
      }
    }
  }, [props.rulesMetaData, props.metaData]);

  useEffect(() => {
    if (props.wizardData.ifWizard.length > 0) {
      let targetCriteriaList = [];
      let targetCriteriaData = props.wizardData.ifWizard;
      for (let i = 0; i < targetCriteriaData.length; i++) {
        if (targetCriteriaData[i].target) {
          let obj = {
            targetName: targetCriteriaData[i].target,
            conditionFilter: targetCriteriaData[i].expressions.operand,
            conditionName: targetCriteriaData[i].expressions.operator,
            unitName: targetCriteriaData[i].expressions.unit,
            conditionValue:
              targetCriteriaData[i].expressions.value[0].inputExpression.input,
            switch: targetCriteriaData[i].expressions.value[0].inputType,
            conditionNameOperand:
              targetCriteriaData[i].expressions.value[0].inputExpression
                .operand,
          };
          targetCriteriaList.push(obj);
        }
      }
      setFormValues(targetCriteriaList);
      if (targetCriteriaList.length < 1) {
        setFormValues([
          ...formValues,
          {
            targetName: "",
            conditionFilter: "",
            conditionName: "",
            unitName: "",
            conditionValue: "",
            switch: "List",
            conditionNameOperand: "",
          },
        ]);
      }
    } else {
      if (formValues.length < 1) {
        setFormValues([
          ...formValues,
          {
            targetName: "",
            conditionFilter: "",
            conditionName: "",
            unitName: "",
            conditionValue: "",
            switch: "List",
            conditionNameOperand: "",
          },
        ]);
      }
    }
  }, []);

  let handleChange = (i, e) => {
    setFormValues((prevFormValues) => {
      let newFormValues = [...prevFormValues];

      if (e.target.name === "switch") {
        // Update the specific field for "switch" value
        newFormValues[i]["switch"] =
          e.target.value === "Calculated" ? "Calculated" : "List";
      }
      if (e.target.name === "conditionFilter") {
        let selectedFilter = e.target.value;
        let findIndex = filterCriteriaDetails.findIndex(
          (obj) => obj.name === selectedFilter
        );
        if (findIndex > -1) {
          let conditionData = filterCriteriaDetails[findIndex];
          newFormValues[i]["unitName"] =
            conditionData.units && conditionData.units.length > 0
              ? conditionData.units[0]
              : "";
        }
      }
      if (e.target.name === "conditionValue") {
        newFormValues[i]["conditionValue"] = e.target.value;
      } else if (e.target.name === "additionalValue") {
        newFormValues[i]["additionalValue"] = e.target.value;
      } else {
        newFormValues[i][e.target.name] = e.target.value;

        setShowAdditionalInput(
          (e.target.name === "conditionName" && e.target.value === "Between") ||
            (e.target.name === "unitName" &&
              newFormValues[i]["conditionName"] === "Between")
        );
      }

      let fromValue = {
        inputType: "List",
        inputExpression: {
          operand: "",
          input: newFormValues[i]["conditionValue"],
        },
      };

      let toValue = {
        inputType: "List",
        inputExpression: {
          operand: "",
          input: newFormValues[i]["additionalValue"],
        },
      };

      let formData = {
        type: "ifData",
        ifData: [fromValue, toValue],
        unit: newFormValues[i]["unitName"],
      };

      props.parentCallback(formData);
      props.wizardDataCallback(formData);
      wizardDataUpdate(newFormValues);

      return newFormValues;
    });
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        targetName: "",
        conditionFilter: "",
        conditionName: "",
        unitName: "",
        conditionValue: "",
        switch: "List",
        inputType: "List",
        conditionNameOperand: "",
      },
    ]);
  };
  let duplicateFormFields = (i) => {
    let newFormValues = [...formValues];
    let duplicateRow = {
      targetName: newFormValues[i].targetName,
      conditionFilter: newFormValues[i].conditionFilter,
      conditionName: newFormValues[i].conditionName,
      unitName: newFormValues[i].unitName,
      conditionValue: newFormValues[i].conditionValue,
      switch: newFormValues[i].switch,
      conditionNameOperand: newFormValues[i].conditionNameOperand,
    };

    newFormValues.push(duplicateRow);
    setFormValues(newFormValues);
    wizardDataUpdate();
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    wizardDataUpdate(newFormValues);
  };
  let switchFormFields = (i) => {
    setIsSwitch(!isSwitch);
    let newFormValues = [...formValues];
    if (newFormValues[i]["switch"] === "List") {
      // setIsOpen(!isopen);
      newFormValues[i]["switch"] = "Calculated";
    } else {
      newFormValues[i]["switch"] = "List";
    }
    setFormValues(newFormValues);
    console.log();
    wizardDataUpdate();
  };

  let handleSubmit = (event) => {};

  function wizardDataUpdate(newFormValues = []) {
    let targetCriteria = [];
    for (let i = 0; i < newFormValues.length; i++) {
      let obj = {
        logicalAND: true,
        target: newFormValues[i].targetName,
        expressions: {
          operand: newFormValues[i].conditionFilter,
          operator: newFormValues[i].conditionName,
          unit: newFormValues[i].unitName,
        },
      };

      // Handle "Between" condition
      if (newFormValues[i].conditionName === "Between") {
        obj.expressions.value = [
          {
            inputType: newFormValues[i].switch,
            inputExpression: {
              operand: newFormValues[i].conditionNameOperand,
              input: newFormValues[i].conditionValue,
            },
          },
          {
            inputType: newFormValues[i].switch,
            inputExpression: {
              operand: newFormValues[i].conditionNameOperand,
              input: newFormValues[i].additionalValue,
            },
          },
        ];
      } else {
        // Handle other conditions
        obj.expressions.value = [
          {
            inputType: newFormValues[i].switch,
            inputExpression: {
              operand: newFormValues[i].conditionNameOperand,
              input: newFormValues[i].conditionValue,
            },
          },
        ];
      }

      targetCriteria.push(obj);
    }

    let formData = {
      type: "targetCriteria",
      targetCriteria: targetCriteria,
    };
    props.parentCallback(formData);
    props.wizardDataCallback(formData);
  }

  function buildConditionDropdownData(listData, metaDataList) {
    let result = metaDataList;
    if (listData && result) {
      for (let i = 0; i < listData.length; i++) {
        listData[i]["id"] = i + 1;
        listData[i]["selected"] = false;
        let slectedConditionType =
          listData[i]["filterConditionType"].toLowerCase();
        let conditionTypeList = result[slectedConditionType];
        listData[i]["conditionTypes"] = conditionTypeList;
      }
      setFilterCriteriaDetails(listData);
    }
  }
  const SubSelectOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.conditionFilter) {
      let findIndex = filterCriteriaDetails.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      console.log(filterCriteriaDetails);
      if (findIndex > -1) {
        let conditionData = filterCriteriaDetails[findIndex];
        let optionData = conditionData.conditionTypes;
        if (optionData) {
          if (optionData.length > 0) {
            return optionData.map((options, i) => (
              <option key={i} value={options.value}>
                {options.name}
              </option>
            ));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const UnitSelectOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.conditionFilter) {
      let findIndex = filterCriteriaDetails.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      if (findIndex > -1) {
        let conditionData = filterCriteriaDetails[findIndex];
        let optionData = conditionData.units;
        if (optionData) {
          if (optionData.length > 0) {
            return optionData.map((options, i) => (
              <option key={i} value={options}>
                {options}
              </option>
            ));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const ConditionNameOperandSelectOptions = (props) => {
    let { index, eleData, filterCriteriaDetails, handleChange } = props;
    if (eleData.conditionNameOperand) {
      let findIndex = filterCriteriaDetails.findIndex(
        (obj) => obj.name === eleData.conditionNameOperand
      );
      if (findIndex > -1) {
        let conditionData = filterCriteriaDetails[findIndex];
        let optionData = conditionData.units;
        if (optionData && optionData.length > 0) {
          return optionData.map((options, i) => (
            <option key={i} value={options}>
              {options}
            </option>
          ));
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="ifpage-css">
        <div className="wizard-step">
          <h5>If</h5>
          {!metaDataLoading && !ruleDataLoading ? (
            <>
              {formValues.map((element, index) => (
                <>
                  {index !== 0 && (
                    <div className="rule-and-or-container">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={"operation" + index}
                          id={"andOperation" + index}
                          checked
                        />
                        <label
                          className="form-check-label"
                          for={"andOperation" + index}
                        >
                          And
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={"operation" + index}
                          id={"orOperation" + index}
                        />
                        <label
                          className="form-check-label"
                          for={"orOperation" + index}
                        >
                          Or
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="card">
                    <Row>
                      <Col md={12} style={{ flexGrow: 1 }}>
                        <form>
                          <div className="form-row">
                            <div className="ele-move-box">
                              <img src={moveIcon} alt="" />
                            </div>

                            <div className="form-field-group">
                              <div className="form-field ">
                                <select
                                  className="form-select ifpage-fom1  focus-border-green"
                                  name="targetName"
                                  value={element.targetName || ""}
                                  onChange={(e) => handleChange(index, e)}
                                >
                                  <option value="">--Select--</option>
                                  {targetCriteriaDetails.map((list, i) => (
                                    <option value={list} key={i}>
                                      {list}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="form-field ">
                                <select
                                  className="form-select focus-border-green"
                                  name="conditionFilter"
                                  value={element.conditionFilter || ""}
                                  onChange={(e) => handleChange(index, e)}
                                >
                                  <option value="">--Select--</option>
                                  {filterCriteriaDetails.map((list, i) => (
                                    <option value={list.name} key={i}>
                                      {list.displayName}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="form-field">
                                <select
                                  className="form-select  focus-border-green"
                                  name="conditionName"
                                  value={element.conditionName || ""}
                                  onChange={(e) => handleChange(index, e)}
                                >
                                  <option value="">--Select--</option>
                                  <SubSelectOptions
                                    index={index}
                                    eleData={element}
                                  />
                                </select>
                              </div>

                              <div
                                className="form-field"
                                style={{ display: "none" }}
                              >
                                <select
                                  className="form-select  focus-border-green"
                                  name="unitName"
                                  value={element.unitName || ""}
                                  onChange={(e) => handleChange(index, e)}
                                >
                                  <option value="">--Select--</option>
                                  {!isSwitch ? (
                                    <UnitSelectOptions
                                      index={index}
                                      eleData={element}
                                    />
                                  ) : (
                                    <ConditionNameOperandSelectOptions
                                      index={index}
                                      eleData={element}
                                      filterCriteriaDetails={
                                        filterCriteriaDetails
                                      }
                                      handleChange={handleChange}
                                    />
                                  )}
                                </select>
                              </div>

                              {element.switch === "Calculated" ? (
                                <>
                                  <div className="form-field  rule-switch-container">
                                    <div className="width-80p">
                                      <select
                                        className="form-select  focus-border-green"
                                        name="conditionNameOperand"
                                        value={
                                          element.conditionNameOperand || ""
                                        }
                                        onChange={(e) => handleChange(index, e)}
                                      >
                                        <option value="">
                                          --Select metric--
                                        </option>
                                        {filterCriteriaDetails.map(
                                          (list, i) => (
                                            <option value={list.name} key={i}>
                                              {list.displayName}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    <div className="multiply-sympol-box">x</div>
                                    <div className="ifpage-css">
                                      <input
                                        type="text"
                                        placeholder="enter value"
                                        name="conditionValue"
                                        className="form-control"
                                        value={element.conditionValue || ""}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {showAdditionalInput ? (
                                    <div
                                      className="form-field width-50p"
                                      style={{ display: "flex", flexGrow: "1" }}
                                    >
                                      <input
                                        type="text"
                                        placeholder="enter value"
                                        name="conditionValue"
                                        className="form-control"
                                        value={element.conditionValue || ""}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="form-field width-100p"
                                      style={{ display: "flex", flexGrow: "1" }}
                                    >
                                      <input
                                        type="text"
                                        placeholder="enter value"
                                        name="conditionValue"
                                        className="form-control"
                                        value={element.conditionValue || ""}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </div>
                                  )}
                                </>
                              )}

                              {showAdditionalInput && (
                                <div style={{ display: "flex", width: "60%" }}>
                                  <div
                                    style={{
                                      margin: "0 10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    To
                                  </div>
                                  <div className="form-field width-100p">
                                    <input
                                      type="text"
                                      placeholder="enter value"
                                      name="additionalValue"
                                      className="form-control"
                                      value={element.additionalValue || ""}
                                      onChange={(e) => handleChange(index, e)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="form-action a-3">
                              <div
                                className="icon-box-white cursor"
                                onClick={() => switchFormFields(index)}
                              >
                                <img src={SwitchIcon} alt="" />
                              </div>
                              <div
                                className="icon-box-white cursor"
                                onClick={() => duplicateFormFields(index)}
                              >
                                <img src={DuplicateIcon} alt="" />
                              </div>
                              {index !== 0 && (
                                <div
                                  className="icon-box-gray cursor"
                                  onClick={() => removeFormFields(index)}
                                >
                                  <img src={RemoveIcon} alt="" />
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </Col>
                    </Row>
                  </div>
                </>
              ))}
            </>
          ) : (
            <div className="loading-container">
              <div className="loading-text">
                <span className="logicon">
                  <img src={LogoIcon} alt="logo-small"></img>
                </span>
                <span>L</span>
                <span>O</span>
                <span>A</span>
                <span>D</span>
                <span>I</span>
                <span>N</span>
                <span>G</span>
              </div>
            </div>
          )}

          <div>
            <button
              type="button"
              className="btn btn-link add-more-btn"
              onClick={() => addFormFields()}
            >
              <i class="circle-add-icon"></i> Add Row
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddRulesIf;
