import React, { useState, useRef, useEffect } from 'react';
import './DynamicWidthDropdown.css';
import InfoButton from "../../Common/InfoButton";

const DynamicWidthDropdown = ({ options, selectedItem, handleSelectChange, defaultText="Select" }) => {
    const [dropdownWidth, setDropdownWidth] = useState('');
    const [selectedItemData, setSelectedItemData] = useState({});
    const dropdownRef = useRef(null);

    useEffect(() => {
        updateDropdownWidth();
    }, []);

    useEffect(() => {
        updateDropdownWidth();
    }, [selectedItem]);

    const updateDropdownWidth = () => {
        if (dropdownRef.current && selectedItem) {
            // Get the width of the selected item element
            const foundItem = options?.find(option => option.name === selectedItem);
            if (foundItem) {
                setSelectedItemData(foundItem);
                dropdownRef.current.textContent =  `${foundItem.title}`;
            }

            const selectedItemWidth = dropdownRef.current.offsetWidth;
            // Set the dropdown width to the width of the selected item + some padding
            setDropdownWidth(`${selectedItemWidth + 18}px`);
        }

    };


    return (
        <div className="DynamicWidthDropdown">
            <select
                className="form-select widget-select"
                ref={dropdownRef}
                value={selectedItem}
                onChange={handleSelectChange}
                style={{ width: dropdownWidth }}
            >
                <option value="" disabled>
                    {defaultText}
                </option>
                {options?.map((option) => (
                    <option title={option.description} key={option.name} value={option.name}>
                        {option.title} {option.subTitle ? `[${option.subTitle}]` : ''}
                    </option>
                ))}
            </select>
            <span className='gray-text-m'>{selectedItemData && selectedItemData.subTitle && (
                <>[{selectedItemData.subTitle}]</>
            )}</span>
            <InfoButton infoText={selectedItemData.description} />
            <span ref={dropdownRef} style={{ visibility: 'hidden', position: 'absolute', whiteSpace: 'nowrap' }}>
            </span>
        </div>
    );
};

export default DynamicWidthDropdown;
