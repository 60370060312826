import React, { Component } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Navigate } from "react-router-dom";
import AwsConnectIcon from "../../assets/images/icons/aws-connect-icon.svg";
import LogoIcon from "../../assets/brand/logo-icon.svg";
import ProfileSelector from "../../components/Common/ProfileSelector/ProfileSelector";
import AmazonAdsService from "../../services/AmazonAdsService";
import UserService from "../../services/UserService";
interface FormState {
  message: string;
  redirect: string | null;
  awsProfilePreferences: any[]; // Assuming that awsProfilePreferences is an array of objects
  atLeastOneSelected: boolean;
  loading: boolean;
  isCheckAll: boolean;
  selectedProfiles: any[];
  /*dataSource: any[];*/
}
class OnboardSuccess extends Component<{}, FormState> {
  loading = false;
  users: any = {};

  constructor(props: {}) {
    super(props);
    //dataSource: [
    //    {
    //        countryCode: "CA",
    //        isActive: true,
    //        profileId: "3771059006499901",
    //        profileName: "Sponsored ads - KDP",
    //        type: "vendor"
    //    },
    //    {
    //        countryCode: "US",
    //        isActive: true,
    //        profileId: "1270445463705298",
    //        profileName: "Sponsored ads - KDP",
    //        type: "vendor"
    //    },
    //    {
    //        countryCode: "IT",
    //        isActive: true,
    //        profileId: "2550947509735833",
    //        profileName: "Sponsored ads - KDP",
    //        type: "vendor"
    //    },
    //    {
    //        countryCode: "ES",
    //        isActive: true,
    //        profileId: "4325562197843487",
    //        profileName: "Sponsored ads - Author",
    //        type: "vendor"
    //    },
    //    {
    //        countryCode: "UK",
    //        isActive: true,
    //        profileId: "879988204029019",
    //        profileName: "Sponsored ads - KDP",
    //        type: "vendor"
    //    },
    //    {
    //        countryCode: "DE",
    //        isActive: true,
    //        profileId: "1320665765665352",
    //        profileName: "Sponsored ads - KDP",
    //        type: "vendor"
    //    },
    //    {
    //        countryCode: "FR",
    //        isActive: true,
    //        profileId: "1981741937699488",
    //        profileName: "Sponsored ads - KDP",
    //        type: "vendor"
    //    },
    //    {
    //        countryCode: "AU",
    //        isActive: true,
    //        profileId: "3645851720345100",
    //        profileName: "Sponsored ads - KDP",
    //        type: "vendor"
    //    }
    //],

    this.state = {
      selectedProfiles: [],

      awsProfilePreferences: [],
      message: "Loading....",
      redirect: null,
      atLeastOneSelected: false,
      loading: false,
      isCheckAll: false,
    };
  }

  componentDidMount = async () => {
    if (this.users.email === "" || this.users.email === undefined) {
      // this.getUserProfile();
    }
    if (this.loading === false) {
      console.log(window.location.href.split("="));
      let param1 = window.location.href.split("=")[1];
      let code = param1.split("&")[0];
      let param2 = window.location.href.split("=")[2];
      let scope = param2.split("&")[0];
      let param3 = window.location.href.split("=")[3];
      let state = param3.split("&")[0];

      let requestQuery = {
        code: code,
        name: "",
        scope: scope,
        state: state,
      };
      this.setState({ loading: true });
      this.amazonAccountSubmit(requestQuery);
      this.loading = true;
    }
  };

  amazonAccountSubmit = async (bodyQuery: any) => {
    try {
      this.setState({ loading: true });
      const responseData = await AmazonAdsService.Callback(bodyQuery);
      if (responseData) {
        await this.getUserProfile();
      }
    } catch (error) {
      console.error("Error fetching metric data:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  getUserProfile = async () => {
    try {
      this.setState({ loading: true });
      const users = await UserService.GetUserProfile();
      if (users) {
        this.setState({
          awsProfilePreferences: users.awsProfilesPreferences,
          loading: false, // Set loading state to false when user profile is fetched
        });
      }
    } catch (error) {
      console.error("Error fetching metric data:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleProfileSelectionChange = (selectedProfiles: any) => {
    this.setState({
      selectedProfiles: selectedProfiles,
    });
  };

  updateProfilePreferences = async () => {
    if (
      !this.state.selectedProfiles ||
      this.state.selectedProfiles.length < 1
    ) {
      return;
    }

    const request = {
      awsProfilePreferences: this.state.selectedProfiles.map((item) => ({
        profileId: item.profileId,
        IsActive: true,
      })),
    };

    try {
      const response = await UserService.UpdateProfilePreferences(request);
      if (response) {
        this.setState({
          redirect: "/onboarding/browser-extension",
        });
      }
      const countryCode: any = this.state.selectedProfiles.map(
        (item) => item.countryCode
      );
      localStorage.setItem("countryCode", countryCode);
      console.log("UpdateProfilePreferences response:", response);
    } catch (error) {
      // Handle the error
      console.error("Error updating profile preferences:", error);
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    console.log(this.state.selectedProfiles);

    return (
      <DefaultLayout>
        <div className="contanier-type2">
          <form>
            <div className="center-title">
              <h2>Welcome to Aimosa</h2>
              <p>Getting ready to begin...</p>
            </div>

            <div className="mb-3">
              <h3 className="mb-2">
                <span>
                  <img
                    className="title-image"
                    src={AwsConnectIcon}
                    alt="aws connect"
                  />
                </span>
                Connect Amazon Data
              </h3>

              {this.state.awsProfilePreferences ? (
                <p>
                  We found{" "}
                  <strong>
                    {this.state.awsProfilePreferences.length} available
                    merchants
                  </strong>{" "}
                  ready to connect.
                </p>
              ) : (
                <p>
                  We found <strong>0 available merchants</strong> ready to
                  connect.
                </p>
              )}

              {this.state.loading ? (
                <div className="loading-container">
                  <div className="loading-text">
                    <span className="logicon">
                      <img src={LogoIcon} alt="logo-small"></img>
                    </span>
                    <span>L</span>
                    <span>O</span>
                    <span>A</span>
                    <span>D</span>
                    <span>I</span>
                    <span>N</span>
                    <span>G</span>
                  </div>
                </div>
              ) : (
                <ProfileSelector
                  dataSource={this.state.awsProfilePreferences}
                  onChange={this.handleProfileSelectionChange}
                  value={this.state.selectedProfiles}
                ></ProfileSelector>
              )}
            </div>

            <div className="d-grid onboard-success-btn">
              <div className="row">
                <div className="col-md-6">
                  <button type="button" className="btn btn-primary explore">
                    Back
                  </button>
                </div>
                <div className="col-md-6">
                  {this.state.loading ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => window.location.reload()}
                    >
                      Refresh
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={this.state.selectedProfiles?.length < 1}
                      onClick={this.updateProfilePreferences}
                    >
                      Continue
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </DefaultLayout>
    );
  }
}

export default OnboardSuccess;
