// NumberFormatter.js
import React from 'react';

const formatNumber = (value) => {
    if (value >= 1e6) {
        return (value / 1e6).toFixed(1) + 'M';
    } else if (value >= 1e3) {
        return (value / 1e3).toFixed(1) + 'K';
    } else {
        return value.toString();
    }
};

const NumberFormatter = ({ value }) => {
    const formattedValue = formatNumber(value);

    return <text title={value}>{formattedValue}</text>;
};

export default NumberFormatter;
