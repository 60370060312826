import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Col, Row, Spinner } from 'react-bootstrap';
import MoreActionIcon from '../../assets/images/icons/more-action-icon.svg';
import DashboardService from '../../services/DashboardService';
import MasterDataService from '../../services/MasterDataService';
import DynamicWidthDropdown from '../Common/DynamicWidthDropdown/DynamicWidthDropdown';
import InfoButton from '../Common/InfoButton';

interface HorizontalChartProps {
    defaultCategory: string;
    defaultMetric: string;
    canSwitch: bool;
    filters: any; // Replace with the actual type for your filters
}


const HorizontalChart: React.FC<HorizontalChartProps> = ({ defaultCategory, defaultMetric, canSwitch, filters }) => {
    const [loading, setLoading] = useState(true);
    const [categoryList, setCategoryList] = useState([]);

    function getListItemByKey(listItem, name) {
        // Check if listItem is an array
        if (Array.isArray(listItem)) {
            // Use find only if listItem is an array
            return listItem.find(item => item.name === name) ?? null;
        } else {
            // Return null if listItem is not an array
            return null;
        }
    }
    function getChartOptions(categories) {
        return {
            chart: {
                type: 'bar',
                height: 400
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ["#00645D", "#CAFCD0", "#00D26E"],
            xaxis: {
                categories: categories,
            },
        };
    }
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory || 'Marketplace');
    const [selectedMetric, setSelectedMetric] = useState(defaultMetric);
    const [chartSeries, setChartSeries] = useState([{ name: defaultMetric, data: [] }]);
    const [selectedCategoryData, setSelectedCategoryData] = useState({});
    const [selectedMetricData, setSelectedMetricData] = useState({
        name: '',
        unit: '',
        description: '',
        value: 0.0,
        average: 0.0,
        sum: 0.0,
        min: 0.0,
        max: 0.0,
        growthRate: 0.0,
        data: []
    });
    const [chartOptions, setChartOptions] = useState(getChartOptions(['US']));

    function setDropDowns(catList) {
        setCategoryList(catList);
        let categoryData = getListItemByKey(catList, selectedCategory);
        if (!categoryData) {
            categoryData = catList[0];
            setSelectedCategory(categoryData.name);
        }
        setSelectedCategoryData(categoryData);
        let metricData = getListItemByKey(categoryData.childValues, selectedMetric);
        if (!metricData) {
            metricData = categoryData.childValues[0];
            setSelectedMetric(metricData.name);
        }
        setSelectedMetricData(metricData);
    }

    useEffect(() => {
        const fetchMetaData = async () => {
            setLoading(true);
            try {
                const metaData = await MasterDataService.getMetaData();
                if (metaData.HorizontalChart) {
                    setDropDowns(metaData.HorizontalChart);
                }
            } catch (error) {
                console.error('Error fetching metrics list:', error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchMetaData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [selectedCategory, selectedMetric, categoryList, filters]);

    const fetchData = async () => {
        setLoading(true);
        try {

            var request = {
                globalFilters: filters,
                categories: selectedCategory,
                metrics: selectedMetric
            };
            const horizontalChartData = await DashboardService.getHorizontalChartData(request);

            if (horizontalChartData) {
                const series = horizontalChartData.data.map((item) => parseFloat(Number(item.xAxis).toFixed(2)));
                const labels = horizontalChartData.data.map((item) => item.yAxis);
                setChartSeries([{ name: selectedMetric, data: series }]);
                setChartOptions(getChartOptions(labels));
            }

        } catch (error) {
            console.error('Error fetching metric data:', error);
        }
        finally {
            setLoading(false);
        }
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setDropDowns(categoryList);
    };

    const handleMetricChange = (event) => {
        setSelectedMetric(event.target.value);
        let metricData = getListItemByKey(selectedCategoryData.childValues, selectedMetric);
        if (!metricData) {
            metricData = selectedCategoryData.childValues[0];
            setSelectedMetric(metricData.name);
        }
        setSelectedMetricData(metricData);
    };

    return (
        <div className="widget-container height-410px">
            <div className="widget-header">
                <Row>
                    <Col md={7} className="widget-select-container dot-con">
                        <div className="input-group filter d-flex align-items-center">
                            <div
                                style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    display: 'inline-flex'
                                }}
                            >
                                {(!canSwitch) && (
                                    <>
                                        {selectedCategoryData && (
                                            <>
                                                <span className="mx-2" style={{
                                                    color: '#111827',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '600',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word'
                                                }}>{selectedCategoryData.title}</span>
                                                <span style={{
                                                    color: '#9CA3AF',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '500',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word'
                                                }}>{selectedCategoryData.subTitle && (<span>[{selectedCategoryData.subTitle}]</span>)}</span>
                                                <InfoButton infoText={selectedCategoryData.description} />
                                            </>
                                        )}
                                    </>
                                )}
                                {canSwitch && categoryList && categoryList.length > 0 && (
                                    <DynamicWidthDropdown
                                        options={categoryList}
                                        selectedItem={selectedCategory}
                                        handleSelectChange={handleCategoryChange}
                                    ></DynamicWidthDropdown>
                                )}

                                <span className="mx-3">By</span>

                                {(!canSwitch) && (
                                    <>
                                        {selectedMetricData && (
                                            <>
                                                <span className="mx-2" style={{
                                                    color: '#111827',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '600',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word'
                                                }}>{selectedMetricData.title}</span>
                                                <span style={{
                                                    color: '#9CA3AF',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '500',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word'
                                                }}>{selectedMetricData.subTitle && (<span>[{selectedMetricData.subTitle}]</span>)}</span>
                                                <InfoButton infoText={selectedMetricData.description} />
                                            </>
                                        )}
                                    </>
                                )}
                                {canSwitch && selectedCategoryData.childValues && selectedCategoryData.childValues.length > 0 && (
                                    <DynamicWidthDropdown
                                        options={selectedCategoryData.childValues}
                                        selectedItem={selectedMetric}
                                        handleSelectChange={handleMetricChange}
                                    ></DynamicWidthDropdown>
                                )}

                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <Row>
                            <Col md={10}></Col>
                            <Col md={2}>
                                <div className="more-action-icon">
                                    <img src={MoreActionIcon} alt="info circle icon" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="app">
                <div className="row">
                    <div className="mixed-chart">
                        {
                            loading ? (
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        height: "100%",
                                        marginTop: "140px",
                                    }}
                                    className="horizontalBarChart"
                                >
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    <br></br>
                                    <div className="loading ms-4">Loading...</div>
                                </div>
                            )
                                :
                                (
                                    (chartSeries && chartSeries.length > 0) ?
                                        (
                                            <Chart
                                                id="horizontal_bar_chart"
                                                options={chartOptions}
                                                series={chartSeries}
                                                type="bar"
                                                width="100%"
                                                height={400}
                                            />
                                        )
                                        :
                                        (
                                            < div style={{
                                                width: "100%",
                                                textAlign: "center",
                                                height: "100%",
                                                marginTop: "140px",
                                            }}
                                            >
                                                No Data
                                            </div>
                                        )

                                )
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default HorizontalChart;
