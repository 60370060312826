import React, { Component, useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import InfoButton from '../Common/InfoButton';
import MoreActionIcon from '../../assets/images/icons/more-action-icon.svg';
import DashboardService from '../../services/DashboardService';
import MasterDataService from '../../services/MasterDataService';
import DynamicWidthDropdown from '../Common/DynamicWidthDropdown/DynamicWidthDropdown';
import NumberFormatter from '../Common/NumberFormatter';

interface FunnelProps {
    defaultCategory: string;
    canSwitch: bool;
    filters: any; // Replace with the actual type for your filters
}

const Funnel: React.FC<FunnelProps> = ({ defaultCategory, canSwitch, filters }) => {
    const [loading, setLoading] = useState(true);
    const [categoryList, setCategoryList] = useState([
        { name: 'Marketplace', title: 'Marketplace', subTitle: '', description: 'Marketplace' },
        { name: 'Ad Portfolio', title: 'Ad Portfolio', subTitle: '', description: 'Ad Portfolio' },
        { name: 'Ad Type', title: 'Ad Type', subTitle: '', description: 'Ad Type' }
    ]);
    function getCategoryByKey(name) {
        return categoryList.find(category => category.name === name);
    }
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory || 'Marketplace');
    const [selectedCategoryItem, setSelectedCategoryItem] = useState(getCategoryByKey(selectedCategory));
    const [chartData, setChartData] = useState({
        "impressions": 0,
        "ctr": 0.0,
        "clicks": 0,
        "cr": 0,
        "orders_Ad": 0
    });

    useEffect(() => {
        fetchMetaData();
    }, []);

    const fetchMetaData = async () => {
        setLoading(true);
        try {
            const metaData = await MasterDataService.getMetaData();
            if (metaData.FunnelChart) {
                setCategoryList(metaData.FunnelChart);
            }
        } catch (error) {
            console.error('Error fetching metrics list:', error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMetricsList();
    }, [selectedCategory, categoryList, filters]);

    const fetchMetricsList = async () => {
        setLoading(true);
        try {
            var request = {
                globalFilters: filters,
                category: selectedCategory
            };

            const funnelChartData = await DashboardService.getFunnelChartData(request);
            setChartData(funnelChartData);

        } catch (error) {
            console.error('Error fetching metrics list:', error);
        }
        finally {
            setLoading(false);
        }
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setSelectedCategoryItem(getCategoryByKey(event.target.value));
    };

    return (
        <div className="widget-container height-350px">
            <div className="widget-header">
                <Row>
                    <Col md={10} className="widget-select-container">
                        <div className="input-group filter d-flex align-items-baseline">

                            <div
                                style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    display: 'inline-flex'
                                }}
                            >
                                {(!canSwitch) && (
                                    <>
                                        {selectedCategoryItem && (
                                            <>
                                                <span className="mx-2" style={{
                                                    color: '#111827',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '600',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word'
                                                }}>{selectedCategoryItem.title}</span>
                                                <span style={{
                                                    color: '#9CA3AF',
                                                    fontSize: '14px',
                                                    fontFamily: 'Plus Jakarta Sans',
                                                    fontWeight: '500',
                                                    lineHeight: '21px',
                                                    wordWrap: 'break-word'
                                                }}>{selectedCategoryItem.subtitle && (<span>[{selectedCategoryItem.subtitle}]</span>)}</span>
                                            </>
                                        )}
                                    </>
                                )}
                                {canSwitch && categoryList && categoryList.length > 0 && (
                                    <DynamicWidthDropdown
                                        options={categoryList}
                                        selectedItem={selectedCategory}
                                        handleSelectChange={handleCategoryChange}
                                    ></DynamicWidthDropdown>
                                )}

                            </div>
                        </div>
                    </Col>
                    <Col md={2} className="p-0">
                        <div className="more-action-icon">
                            <img src={MoreActionIcon} alt="info circle icon" />
                        </div>
                    </Col>
                </Row>
            </div>
            {
                loading ?
                    (
                        <div
                            style={{
                                width: "100%",
                                textAlign: "center",
                                height: "100%",
                                marginTop: "140px",
                            }}
                            className="horizontalBarChart"
                        >
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <br></br>
                            <div className="loading ms-4">Loading...</div>
                        </div>
                    ) :
                    (
                        <Row className="justify-content-center">
                            <Col md={9}>
                                <div className="div-container-funnel pt-4">
                                    <div className="div1">Impression:<NumberFormatter value={chartData.impressions}></NumberFormatter></div>
                                    <div className="div1">CTR: {chartData.ctr}%</div>
                                    <div className="div1">Clicks: <NumberFormatter value={chartData.clicks}></NumberFormatter></div>
                                    <div className="div1">CVR: {chartData.cr}%</div>
                                    <div className="div1">Orders: <NumberFormatter value={chartData.orders_Ad}></NumberFormatter></div>
                                </div>
                            </Col>
                        </Row>
                    )
            }

        </div>

    );
};

export default Funnel;
